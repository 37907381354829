import { DEFAULT_TOAST_LIFE } from "../../../common/EzToast";
import {
  FETCH_COMPANY_DETAIL,
  INIT_COMPANY,
  FETCH_COMPANY_LIST_START,
  FETCH_COMPANY_LIST_SUCCESS,
  FETCH_COMPANY_LIST_ERROR,
  TOAST,
} from "../../../constants/action-types/company";
import { companyService } from "../../../Services";

export const initializeCompanyScreen = () => ({ type: INIT_COMPANY });

export const companyFetchStart = (payload) => ({

  type: FETCH_COMPANY_LIST_START,
  payload,
});

export const companyFetchSuccess = (payload) => ({
  type:FETCH_COMPANY_LIST_SUCCESS,
  payload,
});

export const companyFetchError = (payload) => ({
  type: FETCH_COMPANY_LIST_ERROR,
  payload,
});

export const fetchCompany = (payload) => {
  return (dispatch) => {
    dispatch(companyFetchStart(payload));

    companyService.fetchCompany(payload).then((data) => {
      let nData = {
        page:1,
        content:[data]
      };
      if (!data.isError) {
        dispatch(companyFetchSuccess(nData));
      
      } else {
        dispatch(companyFetchError(data));
      }
    });
  };
};
