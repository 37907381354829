import {
  INIT_PROVINCE,
  FETCH_PROVINCE_LIST_START,
  FETCH_PROVINCE_LIST_SUCCESS,
  FETCH_PROVINCE_LIST_ERROR,
  FETCH_PROVINCE_LIST_BY_COUNTRY,
} from "../../../constants/action-types/common/province";

import { provinceService } from "../../../Services";

export const initializeProvinceScreen = () => ({ type: INIT_PROVINCE });

export const provinceFetchStart = () => ({ type: FETCH_PROVINCE_LIST_START });

export const provinceFetchSuccess = (payload) => ({
  type: FETCH_PROVINCE_LIST_SUCCESS,
  payload,
});

export const provinceFetchError = (payload) => ({
  type: FETCH_PROVINCE_LIST_ERROR,
  payload,
});

export const fetchProvinces = (payload) => {
  return (dispatch) => {
    dispatch(provinceFetchStart());

    provinceService.fetchProvinces(payload).then((provinceData) => {
      if (!provinceData.isError) {
        dispatch(provinceFetchSuccess(provinceData));
      } else {
        dispatch(provinceFetchError(provinceData));
      }
    });
  };
};

export const fetchProvincesByCountryStart = (payload) => ({
  type: FETCH_PROVINCE_LIST_BY_COUNTRY.START,
  payload,
});

export const fetchProvincesByCountrySuccess = (payload) => ({
  type: FETCH_PROVINCE_LIST_BY_COUNTRY.SUCCESS,
  payload,
});

export const fetchProvincesByCountryError = (payload) => ({
  type: FETCH_PROVINCE_LIST_BY_COUNTRY.ERROR,
  payload,
});
export const fetchProvincesByCountry = (payload) => {
  return (dispatch) => {
    dispatch(fetchProvincesByCountryStart(payload));
    provinceService.fetchProvincesByCountry(payload).then((provinceData) => 
    {
      if (!provinceData.isError) {
        dispatch(fetchProvincesByCountrySuccess(provinceData));
      } else {
        dispatch(fetchProvincesByCountryError(provinceData));
      }
    });
  };
};
