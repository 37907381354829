import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FORM_FIELDS_NAME } from "./constant";
import { getFormBaseValidationRule } from "../../../utils/form/form-utils";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Col, Container, Row } from "react-bootstrap";
import {
  plasticType,
  cardStatusType,
  driverVehivleIdentifier,
  cardProperties,
  cardmonetaryProperties,
  WeeklyFuelPurchaseProperties,
  fuelPurchaseLimitationProperties,
  nonHighwayFuelPurchaseProperties,
  weeksDay,
} from "./data.json";
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import { Divider } from "primereact/divider";
import "./styles.css";
import { loadingsShow } from "../../../Services/PostAPI";
import EzControlledCheckBoxWithLabel from "../../../common/EzForm/EzControlledCheckBox/WithLabel";
import EzControlledInput from "../../../common/EzForm/EzControlledInput";
import { goBack } from "../../../utils/gotoback";

const CreateManageCardComponent = (props) => {
  const {
    isEdit,
    isView,
    isLoading,
    navigateToCardManageSummaryScreen,
    createManageCard,
    editManageCard,
    formFieldValueMap,
    isManageCardDetailSuccess,
    isCreateManageCardSuccess,
    isEditManageCardSuccess,
    isEditManageCardError,
    shouldBlockNavigation,
  } = props;
  console.log("check props of compoemnt", props);
  useEffect(() => {
    setTimeout(() => loadingsShow("none"), 1000);
  }, []);

  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  useEffect(() => shouldBlockNavigation(isDirty), [isDirty]);
  useEffect(() => {
    if (isCreateManageCardSuccess || isEditManageCardSuccess) {
      reset();
      navigateToCardManageSummaryScreen();
    }
  }, [isCreateManageCardSuccess]);

  useEffect(() => {
    if (isManageCardDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isManageCardDetailSuccess]);

  const onSubmit = (data) => {
    const {
      cash_refresh_days,
      non_highway_fuel_weekly_refresh_day,
      weekly_vehicle_quantity_refresh_day,
      ...rest
    } = data;

    const stringifiedData = {
      ...rest,
      cash_refresh_days: JSON.stringify(cash_refresh_days),
      non_highway_fuel_weekly_refresh_day: JSON.stringify(
        non_highway_fuel_weekly_refresh_day
      ),
      weekly_vehicle_quantity_refresh_day: JSON.stringify(
        weekly_vehicle_quantity_refresh_day
      ),
    };
    isEdit
      ? editManageCard({ id: data.id, data: stringifiedData })
      : createManageCard(stringifiedData);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <Button
          tooltip={"Back to Manage Cards summary"}
          tooltipOptions={{ position: "left" }}
          label={"List of ManageCard"}
          // icon={"pi pi-arrow-left"}
          className={`p-button `}
          style={{ borderRadius: "10px" }}
          onClick={navigateToCardManageSummaryScreen}
        />
      </div>
    );
  };

  return (
    <div className="paddingbox">
      <div
        className={classNames("containerboxform", { "p-disabled": isLoading })}
      >
        {renderPageHeaderSection()}
        <div className="form-demo">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <Container fluid className="card-box">
              {renderDividerRowWithTime("Card Management")}
              <Row className="gap-3 g-5">
                <Col md="3">
                  <EzControlledInput
                    name={FORM_FIELDS_NAME.CARD_NUMBER}
                    control={control}
                    label={"Card Number"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME.NUMBER]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.NUMBER)}
                  />
                </Col>
                <Col md="3">
                  <EzControlledInput
                    name={FORM_FIELDS_NAME.COMPANY_NAME}
                    control={control}
                    label={"Company Name"}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    isError={errors[FORM_FIELDS_NAME.COMPANY]}
                    errorMsg={getFormErrorMessage(FORM_FIELDS_NAME.COMPANY)}
                  />
                </Col>
                <Col md="3">
                  <EzControlledDropDowntWithFloatingLabel
                    name={FORM_FIELDS_NAME.PLASTIC_TYPE}
                    control={control}
                    rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                    optionLabel={"name"}
                    optionValue={"name"}
                    options={plasticType}
                    isError={errors[FORM_FIELDS_NAME.PLASTIC_TYPE]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.PLASTIC_TYPE
                    )}
                    label="Plastic Type"
                    labelClassName={"ml-2"}
                  />
                </Col>
              </Row>
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Driver/Vehicle Identifiers")}
              <Row>
                {driverVehivleIdentifier?.map((item) => (
                  <Col md={3} key={item?.label}>
                    <EzControlledInput
                      name={item?.name}
                      control={control}
                      label={item?.label}
                      rules={item?.rule}
                      isError={errors[item?.name]}
                      errorMsg={getFormErrorMessage(item?.name)}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Card Properties")}

              <Row>
                {cardProperties?.map((item) =>
                  item.type !== "dropdown" ? (
                    <Col md={3} key={item?.label}>
                      <EzControlledInput
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    </Col>
                  ) : (
                    <Col md={3} key={item?.label}>
                      <EzControlledDropDowntWithFloatingLabel
                        name={item?.name}
                        control={control}
                        rules={
                          getFormBaseValidationRule().DEFAULT.VALIDATION_RULE
                        }
                        optionLabel={"name"}
                        optionValue={"name"}
                        options={cardStatusType}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                        label={item?.label}
                        labelClassName={"ml-2"}
                      />
                    </Col>
                  )
                )}
              </Row>
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Card Monetary Properties")}

              <Row>
                {cardmonetaryProperties?.map((item) => (
                  <React.Fragment key={item?.label}>
                    {item.name !== FORM_FIELDS_NAME.CASH_REFRESH_DAYS ? (
                     <Col md={3} >
                     <EzControlledInput
                        name={item?.name}
                        control={control}
                        type={item?.type}
                        label={item?.label}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Col>
                    ) : (
                      <Col md={6} >
                      <Row>
                        <Col md={12}>
                          <p> {item.label} </p>
                        </Col>
                        {weeksDay?.map((weekItem) => (
                          <Col
                            xs={6}
                            lg={3}
                            key={`${item.label}_${weekItem.label}`}
                          >
                            <EzControlledCheckBoxWithLabel
                              name={`${item.name}.${weekItem.name}`}
                              control={control}
                              label={weekItem?.label}
                              rules={weekItem?.rule}
                              labelClassName={"ml-2"}
                              defaultChecked={false}
                              isError={errors[`${item.name}.${weekItem.name}`]}
                              errorMsg={getFormErrorMessage(
                                `${item.name}.${weekItem.name}`
                              )}
                            />
                          </Col>
                        ))}
                      </Row>
                      </Col>
                    )}
                  </React.Fragment>
                ))}
              </Row>
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Weekly Fuel Purchase Properties")}
              <Row>
                {WeeklyFuelPurchaseProperties?.map((item) => (
                  <React.Fragment key={item?.label}>
                    {item.name !==
                    FORM_FIELDS_NAME.WEEKLY_VEHICLE_QUANTITY_REFRESH_DAY ? (
                      <Col md={3} >
                      <EzControlledInput
                        name={item?.name}
                        control={control}
                        label={item?.label}
                        type={item?.type}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                      </Col>
                    ) : (
                      <Col md={6}>
                      <Row>
                        <Col md={12}>
                          <p> {item.label} </p>
                        </Col>
                        {weeksDay?.map((weekItem) => (
                          <Col
                            xs={6}
                            lg={3}
                            key={`${item.label}_${weekItem.label}`}
                          >
                            <EzControlledCheckBoxWithLabel
                              name={`${item.name}.${weekItem.name}`}
                              control={control}
                              label={weekItem?.label}
                              rules={weekItem?.rule}
                              labelClassName={"ml-2"}
                              defaultChecked={false}
                              isError={errors[`${item.name}.${weekItem.name}`]}
                              errorMsg={getFormErrorMessage(
                                `${item.name}.${weekItem.name}`
                              )}
                            />
                          </Col>
                        ))}
                      </Row>
                      </Col>
                    )}
                  </React.Fragment>
                ))}
              </Row>
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Fuel Purchase Limitation Properties")}
              <Row>
                {fuelPurchaseLimitationProperties?.map((item) => (
                  <Col md={3} key={item?.label}>
                    <EzControlledInput
                      name={item?.name}
                      control={control}
                      label={item?.label}
                      type={item?.type}
                      rules={item?.rule}
                      isError={errors[item?.name]}
                      errorMsg={getFormErrorMessage(item?.name)}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
            <Container fluid className="card-box">
              {renderDividerRow("Non-Highway Fuel Purchase Properties")}
              <Row>
                {nonHighwayFuelPurchaseProperties?.map((item) => (
                  <Col md={3} key={item?.label}>
                    {item.name !==
                    FORM_FIELDS_NAME.NON_HIGHWAY_FUEL_WEEKLY_REFRESH_DAY ? (
                      <EzControlledInput
                        name={item?.name}
                        control={control}
                        type={item?.type}
                        label={item?.label}
                        rules={item?.rule}
                        isError={errors[item?.name]}
                        errorMsg={getFormErrorMessage(item?.name)}
                      />
                    ) : (
                      <Row>
                        <Col md={12}>
                          <p> {item.label} </p>
                        </Col>
                        {weeksDay?.map((weekItem) => (
                          <Col
                            xs={6}
                            lg={3}
                            key={`${item.label}_${weekItem.label}`}
                          >
                            <EzControlledCheckBoxWithLabel
                              name={`${item.name}.${weekItem.name}`}
                              control={control}
                              label={weekItem?.label}
                              rules={weekItem?.rule}
                              labelClassName={"ml-2"}
                              defaultChecked={false}
                              isError={errors[`${item.name}.${weekItem.name}`]}
                              errorMsg={getFormErrorMessage(
                                `${item.name}.${weekItem.name}`
                              )}
                            />
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Col>
                ))}
              </Row>
            </Container>

            <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                  <Button
                    label="Cancel"
                    type="button"
                    className="p-btn-danger p-button-outlined mt-2"
                    onClick={goBack}
                  />
                </div>
                {isEdit ?
                (<div style={{ margin: "15px 10px", minWidth: "100px" }}>
                  <Button
                    type="submit"
                    label={isEdit ? "Update" : "Save"}
                    className="mt-2 p-button-raised"
                  />
                </div>):""
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const renderDividerRow = (label, align = "top", type = "solid") => {
  return (
    <Row>
      <b className="ml-2">{label || ""}</b>
      <Divider align={align} type={type}></Divider>
    </Row>
  );
};

const renderDividerRowWithTime = (label, align = "top", type = "solid") => {
  return (
    <Row>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          {" "}
          <b className="ml-2">{label || ""}</b>
        </div>
        <div>
          {" "}
          <b className="mr-2">
            <span>Last Updated at</span> {new Date().toLocaleString()}{" "}
          </b>
        </div>
      </div>
      <Divider align={align} type={type} />
    </Row>
  );
};

export default CreateManageCardComponent;
