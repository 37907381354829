import React from "react";
import { Route } from "react-router-dom";

import { APP_URLS } from "../../constants/urlConstants";
import ManagecardsScreen from "../../containers/ManagecardsScreen";
import CreateManageCardContainer from "../../containers/ManagecardsScreen/CreateManagecards";
import NotFound from "../../NotFound/NotFound";

const ManageCardRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update = false } = access;
  // console.log(access, props);

  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <ManagecardsScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.MANAGECARDS.ADD_MANAGECARDS}
          component={CreateManageCardContainer}
        />
     )}
      {!!update && (
        <Route
          path={APP_URLS.MANAGECARDS.EDIT_MANAGECARDS}
          render={(routeProps) => (
            <CreateManageCardContainer {...routeProps} isEdit={true} />
          )}
        />
      )}
      {!!read && (
        <Route
          path={APP_URLS.MANAGECARDS.VIEW_MANAGECARDS}
          render={(routeProps) => (
            <CreateManageCardContainer {...routeProps} isView={true} />
          )}
        />
      )}
    </>
  );
};

export default ManageCardRoutes;
