import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducers from "../Reducers/rootReducers";

/*const saveToLocalStorage = (store) => {
    try {
        const serializedStore = JSON.stringify(store);
        window.localStorage.setItem('store', serializedStore);
    } catch(e) {
        console.log(e);
    }
}

const loadFromLocalStorage = () =>{
    try {
        const serializedStore = window.localStorage.getItem('store');
        if(serializedStore === null) return undefined;
        return JSON.parse(serializedStore);
    } catch(e) {
        console.log(e);
        return undefined;
    }
}
const persistedState = loadFromLocalStorage();
store.subscribe(() => saveToLocalStorage(store.getState()));

*/

const logger = (store) => (next) => (action) => {
  console.log("dispatching", action);
  let result = next(action);
  console.log("next state", store.getState());
  return result;
};

const store = createStore(rootReducers, applyMiddleware(thunk, logger));

export default store;
