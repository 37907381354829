import {
  INIT_CITY,
  FETCH_CITY_LIST_START,
  FETCH_CITY_LIST_SUCCESS,
  FETCH_CITY_LIST_ERROR,
  FETCH_CITY_LIST_BY_PROVINCE,
} from "../../../../constants/action-types/common/city";

const getInitalState = () => ({
  isFetchingCity: false,
  errorInFetchingCity: false,
  citys: [],
  error: null,
});

const resetBooleanStates = (overrides = {}) => {
  return {
    isFetchingCity: false,
    errorInFetchingCity: false,
    ...overrides,
  };
};

export const cityReducer = (state = getInitalState(), action) => {
  switch (action.type) {
    case INIT_CITY: {
      return { ...getInitalState() };
    }

    case FETCH_CITY_LIST_START:
    case FETCH_CITY_LIST_BY_PROVINCE.START: {
      return {
        ...state,
        ...resetBooleanStates({ isFetchingCity: true }),
      };
    }

    case FETCH_CITY_LIST_SUCCESS:
    case FETCH_CITY_LIST_BY_PROVINCE.SUCCESS: {
      const { content } = action.payload;

      return {
        ...state,
        ...resetBooleanStates(),
        citys: content,
      };
    }

    case FETCH_CITY_LIST_ERROR:
    case FETCH_CITY_LIST_BY_PROVINCE.ERROR: {
      const { error } = action;

      return {
        ...state,
        ...resetBooleanStates({ errorInFetchingCity: true }),
        error,
      };
    }
    default:
      return state;
  }
};
