import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Login from "../Login/Login";
import Dashboard from "../Dashboard/Dashboard";
import NotFound from "../NotFound/NotFound";
import AddBorderConnect from "../BorderConnect/AddBorderConnect";
import { APP_URLS } from "../constants/urlConstants";
import ManageCardRoutes from "./routes/manageCardRoutes";
import TransactionRoutes from "./routes/transactionRoutes";
import UserRoutes from "./routes/userRoutes";
import ComapnyRoutes from "./routes/companyRoutes";

import InvoiceRoutes from "./routes/invoicesRoutes";
import ReportingRoutes from "./routes/reportingRoutes";
import SmartBuyRoutes from "./routes/smartbuyRoutes";
import TruckCareRoutes from "./routes/truckcareRoutes";
import Profile from "../Profile/Profile";
import ProfileRoutes from "./routes/profileRoute";
class Routing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access: JSON.parse(localStorage.getItem("menuAccess")),
    };
  }

  Update = () => {
    this.props.switchHeader();
  };
  renderManageCardRoutes = (access) => (
    <Route
      path={APP_URLS.MANAGECARDS.HOME}
      render={(routeProps) => (
        <ManageCardRoutes {...routeProps} access={access.managecardMenu} />
      )}
    />
  );
  renderTransactionRoutes = (access) => (
    <Route
      path={APP_URLS.TRANSACTIONS.HOME}
      render={(routeProps) => (
        <TransactionRoutes {...routeProps} access={access.transactionMenu} />
      )}
    />
  );
  renderCompanyRoutes = (access) => (
    <Route
      path={APP_URLS.COMPANY.HOME}
      render={(routeProps) => (
        <ComapnyRoutes {...routeProps} access={access.companyMenu} />
      )}
    />
  );
  renderReportingRoutes = (access) => (
    <Route
      path={APP_URLS.REPORTING.HOME}
      render={(routeProps) => (
        <ReportingRoutes {...routeProps} access={access.reportingMenu} />
      )}
    />
  );
  renderSmartBuyRoutes = (access) => (
    <Route
      path={APP_URLS.SMARTBUY.HOME}
      render={(routeProps) => (
        <SmartBuyRoutes {...routeProps} access={access.smartbuyMenu} />
      )}
    />
  );
  renderTruckCareRoutes = (access) => (
    <Route
      path={APP_URLS.TRUCKCARE.HOME}
      render={(routeProps) => (
        <TruckCareRoutes {...routeProps} access={access.truckcareMenu} />
      )}
    />
  );
  renderUserRoutes = (access) => (
    <Route
      path={APP_URLS.USERS.HOME}
      render={(routeProps) => (
        <UserRoutes {...routeProps} access={access.userMenu} />
      )}
    />
  );

  renderInvoiceRoutes = (access) => (
    <Route
      path={APP_URLS.INVOICES.HOME}
      render={(routeProps) => (
        <InvoiceRoutes {...routeProps} access={access.invoiceMenu} />
      )}
    />
  );
  renderProfileRoutes = (access) => (
    <Route
      path={APP_URLS.USER_PROFILE.HOME}
      render={(routeProps) => (
        <ProfileRoutes {...routeProps} access={access.userprofileMenu} />
      )}
    />
  );
  


  render() {
    const { access } = this.state;

    return (
      <Switch>
       
        <Route exact path="/" render={() => <Login callBack={this.Update} />} />
        <Route path="/Dashboard" component={Dashboard} />
       {this.renderManageCardRoutes(access)}
         {this.renderTransactionRoutes(access)}
        {this.renderInvoiceRoutes(access)}
        {this.renderUserRoutes(access)} 
        {this.renderCompanyRoutes(access)}
        {this.renderReportingRoutes(access)}
        {this.renderSmartBuyRoutes(access)}
        {this.renderTruckCareRoutes(access)}
        {this.renderProfileRoutes(access)}
        
{/* <Route path={APP_URLS.TRANSACTIONS.ADD_TRANSACTION} component={CreateTransactions}  isEdit={true}/> */}
       {/* <Route path={APP_URLS.TRANSACTIONS.HOME} component={TransactionRoutes} />
         <Route path={APP_URLS.MANAGECARDS.ADD_MANAGECARDS} component={CreateManageCards}  isEdit={true}/> 
        <Route path={APP_URLS.MANAGECARDS.HOME} component={ManageCardRoutes} />
        <Route path={APP_URLS.USERS.HOME} component={UserRoutes} />
        <Route path="/BorderConnect/" component={AddBorderConnect} /> 
        */}
         <Route path="/Profile" component={Profile} /> 
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    );
  }
}

export default Routing;
