import React, { useEffect } from "react";
import { loadingsShow } from "../../Services/PostAPI";
import companyData from "./tableData.json";
import {
  fetchCompany,
  initializeCompanyScreen,
} from "../../Redux/Actions/company";
import { connect } from "react-redux";
import CompanyComponent from "../../components/Company";

const ComapnyScreen = (props) => {
  const {
    fetchCompany,
    initializeCompanyScreen,
    showPageLevelLoader,
    companyListView,
    paginationInfo,
    isLoading,
  } = props;
  useEffect(() => {
    initializeCompanyScreen();
    fetchCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const loadLazyData = (props) => {
    fetchCompany(props);
  };
 
  const companyProps = {
    fetchCompany: loadLazyData,
    ...companyData,
    tableData: {
      ...companyData.tableData,
      rows: companyListView,
    },
    paginationInfo: {
      ...companyData.paginationInfo,
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    isLoading,
  };
  if (showPageLevelLoader) {
    loadingsShow("block");
    return null;
  }
  loadingsShow("none");

  return (
    <div>
      <CompanyComponent {...companyProps} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initializeCompanyScreen: () => dispatch(initializeCompanyScreen()),
    fetchCompany: (payload) => dispatch(fetchCompany(payload)),
    };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    companyListView: selectCompanyList(state.companyReducer),
    showPageLevelLoader: selectIsLoadingPage(state.companyReducer),
    paginationInfo: selectPaginationInfo(state.companyReducer),
    isPageLevelError: selectIsPageLevelError(state.companyReducer),
    isFetchingGridData: selectIsLoading(state.companyReducer),
    
  };
};
const selectIsLoading = (companyReducer) => companyReducer.isLoading;
const selectPaginationInfo = (companyReducer) =>
companyReducer.paginationInfo;
const selectIsLoadingPage = (companyReducer) =>
companyReducer.isLoadingPage;
const selectIsPageLevelError = (companyReducer) =>
companyReducer.isPageLevelError;
const selectCompanyList = (companyReducer) =>
companyReducer.companyListView;
export default connect(mapStateToProps, mapDispatchToProps)(ComapnyScreen);
