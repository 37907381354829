import {
    INIT_COUNTRY,
    FETCH_COUNTRY_LIST_START,
    FETCH_COUNTRY_LIST_SUCCESS,
    FETCH_COUNTRY_LIST_ERROR,
  } from '../../../../constants/action-types/common/country';
  
  const getInitalState = () => ({
    isFetchingCountry: false,
    errorInFetchingCountry: false,
    countries: [],
    error: null,
  });
  
  const resetBooleanStates = (overrides = {}) => {
    return {
      isFetchingCountry: false,
      errorInFetchingCountry: false,
      ...overrides,
    };
  };
  
  export const countryReducer = (state = getInitalState(), action) => {
    switch (action.type) {
      case INIT_COUNTRY: {
        return { ...getInitalState() };
      }
  
      case FETCH_COUNTRY_LIST_START: {
        return {
          ...state,
          ...resetBooleanStates({ isFetchingCountry: true }),
        };
      }
  
      case FETCH_COUNTRY_LIST_SUCCESS: {
        const { content } = action.payload;
  
        return {
          ...state,
          ...resetBooleanStates(),
          countries: content,
        };
      }
  
      case FETCH_COUNTRY_LIST_ERROR: {
        const { error } = action;
  
        return {
          ...state,
          ...resetBooleanStates({ errorInFetchingCountry: true }),
          error,
        };
      }
      default:
        return state;
    }
  };
  