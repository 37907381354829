import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FORM_FIELDS_NAME } from "./constant";
import { getFormBaseValidationRule } from "../../../utils/form/form-utils";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { plasticType, permission, roleType } from "./data.json";
import { loadingsShow } from "../../../Services/PostAPI";
import EzControlledInputWithFloatingLabel from "../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import PermissionScreen from "../PermissionScreen";

const CreateUserComponent = (props) => {
  const {
    isEdit,
    isLoading = false,
    editUser,
    createUser,
    countries,
    provinces,
    citys,
    onCountryChange,
    onProvinceChange,
    onCityChange,
    navigateToUserSummaryScreen,
  } = props;
console.log("diffn",countries,plasticType);
  const [step, setStep] = useState(1);
  const [userPermissions, setUserPermissions] = useState({
    company: "",
    permissions: {},
  });

  useEffect(() => {
    setTimeout(() => loadingsShow("none"), 1000);
    // isLoading=false;
  }, []);

  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    reset,
    trigger,
    setValue,
  } = useForm();

  useEffect(() => {
    setUserPermissions({
      ...userPermissions,
      permissions: {
        "Manage Users": watch(FORM_FIELDS_NAME.MANAGE_USERS),
        "Manage Dashboard": watch(FORM_FIELDS_NAME.MANAGE_DASHBOARD),
        "View Users": watch(FORM_FIELDS_NAME.VIEW_USERS),
        "Manage Fuel Cards": watch(FORM_FIELDS_NAME.MANAGE_FUEL_CARDS),
        "View Fuel Cards": watch(FORM_FIELDS_NAME.VIEW_FUEL_CARDS),
        "View Invoices": watch(FORM_FIELDS_NAME.VIEW_INVOICES),
        "Manage Money Codes": watch(FORM_FIELDS_NAME.MANAGE_MONEY_CODES),
        "View Money Codes": watch(FORM_FIELDS_NAME.VIEW_MONEY_CODES),
        "Approve Money Codes": watch(FORM_FIELDS_NAME.APPROVE_MONEY_CODES),
        "View Transactions": watch(FORM_FIELDS_NAME.VIEW_TRANSACTIONS),
        "Manage Reports": watch(FORM_FIELDS_NAME.MANAGE_REPORTS),
        "View Reports": watch(FORM_FIELDS_NAME.VIEW_REPORTS),
        "Manage Truckcare": watch(FORM_FIELDS_NAME.MANAGE_TRUCKCARE),
      },
    });
  }, [step]);

  const onSubmit = (data) => {
    isEdit ? editUser({ id: data.id, data: data }) : createUser(data);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const validateConfirmPassword = (value) => {
    const enterPassword = watch(FORM_FIELDS_NAME.ENTER_PASSWORD);
    return value === enterPassword;
  };

  const handleCountryChange = (selectedCountry) => {
    onCountryChange(selectedCountry);
    setValue(FORM_FIELDS_NAME.PROVINCE, null);
    setValue(FORM_FIELDS_NAME.CITY, null);
  };

  const handleProvinceChange = (selectedProvince) => {
    onProvinceChange(selectedProvince);
    setValue(FORM_FIELDS_NAME.CITY, null);
  };

  const handleCityChange = (selectedCity) => {
    onCityChange(selectedCity);
  };

  const renderPageHeaderSection = () => {
    return (
      <ListGroup className="d-flex flex-row justify-content-around">
        <ListGroupItem
          className={`  rounded-lg w-100 border-0 ${
            step === 1 ? "bg-body" : "bg-gray"
          }`}
        >
          <div className="d-flex justify-content-start align-items-center">
            <div>
              <Button
                label="1"
                className={`  ${step === 1 ? "" : "tab-index-button"}`}
              />
            </div>
            <div className="ml-2">
              <b>Profile</b>
              <p className="mb-0">User's Personal Information</p>
            </div>
          </div>
        </ListGroupItem>
        <ListGroupItem
          className={`  rounded-lg w-100 border-0 ${
            step === 2 ? "bg-white" : "bg-gray ml-2 mr-2 mt-0"
          }`}
        >
          <div className="d-flex justify-content-start align-items-center">
            <div>
              <Button
                label="2"
                className={`  ${step === 2 ? "" : "tab-index-button"}`}
              />
            </div>
            <div className="ml-2">
              <b>Permission</b>
              <p className="mb-0">User Permission</p>
            </div>
          </div>
        </ListGroupItem>
        <ListGroupItem
          className={`  rounded-lg w-100 border-0 ${
            step === 3 ? "bg-white" : "bg-gray"
          }`}
        >
          <div className="d-flex justify-content-start align-items-center">
            <div>
              <Button
                label="3"
                className={`  ${step === 3 ? "" : "tab-index-button"}`}
              />
            </div>
            <div className="ml-2">
              <b>Submission</b>
              <p className="mb-0">Review & Submit</p>
            </div>
          </div>
        </ListGroupItem>
      </ListGroup>
    );
  };

  return (
    <div className="paddingbox">
      <div
        className={classNames("containerboxform", { "p-disabled": isLoading })}
      >
        <div className="form-demo">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-fluid w-100 bg-white"
          >
            {renderPageHeaderSection()}

            {step === 1 && (
              <>
                <Container fluid>
                  <Row className="justify-content-center">
                    <Col sm={10} md={8} lg={6}>
                      <Container fluid className="card-box">
                        <Row className="gap-3 g-5">
                          <Col sm="12">
                            <EzControlledInputWithFloatingLabel
                              name={FORM_FIELDS_NAME.FIRST_NAME}
                              control={control}
                              rules={
                                getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE
                              }
                              isError={errors[FORM_FIELDS_NAME.FIRST_NAME]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.FIRST_NAME
                              )}
                              label={"First Name"}
                              placeholder={"Enter First Name"}
                              wrapperClass={"d-flex p-2 "}
                              inputStyle={'{"width":"200%"}'}
                            />
                          </Col>
                          <Col sm="12">
                            <EzControlledInputWithFloatingLabel
                              name={FORM_FIELDS_NAME.LAST_NAME}
                              control={control}
                              rules={
                                getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE
                              }
                              isError={errors[FORM_FIELDS_NAME.LAST_NAME]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.LAST_NAME
                              )}
                              label={"Last Name"}
                              placeholder={"Enter Last Name"}
                              wrapperClass={"d-flex p-2"}
                              inputStyle={'{"width":"200%"}'}
                            />
                          </Col>

                          <Col sm="12">
                            <EzControlledInputWithFloatingLabel
                              name={FORM_FIELDS_NAME.EMAIL}
                              control={control}
                              rules={
                                getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE
                              }
                              isError={errors[FORM_FIELDS_NAME.EMAIL]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.EMAIL
                              )}
                              label={"Email"}
                              placeholder={"Enter Email Address"}
                              wrapperClass={"d-flex p-2"}
                              inputStyle={'{"width":"200%"}'}
                            />
                          </Col>
                          <Col sm="12">
                            <EzControlledInputWithFloatingLabel
                              name={FORM_FIELDS_NAME.PHONE}
                              control={control}
                              rules={
                                getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE
                              }
                              isError={errors[FORM_FIELDS_NAME.PHONE]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.PHONE
                              )}
                              label={"Contact"}
                              placeholder={"Enter contact number"}
                              wrapperClass={"d-flex p-2"}
                              inputStyle={'{"width":"200%"}'}
                            />
                          </Col>
                          <Col sm="12">
                            <EzControlledInputWithFloatingLabel
                              name={FORM_FIELDS_NAME.ENTER_PASSWORD}
                              control={control}
                              label={"Enter Password"}
                              rules={
                                getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE
                              }
                              isError={errors[FORM_FIELDS_NAME.ENTER_PASSWORD]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.ENTER_PASSWORD
                              )}
                              placeholder={"Enter Password"}
                              wrapperClass={"d-flex p-2"}
                              inputStyle={'{"width":"200%"}'}
                            />
                          </Col>
                          <Col sm="12">
                            <EzControlledInputWithFloatingLabel
                              name={FORM_FIELDS_NAME.VERIFY_PASSWORD}
                              control={control}
                              label={"Confirm Password"}
                              rules={{
                                ...getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE,
                                validate: validateConfirmPassword,
                              }}
                              isError={errors[FORM_FIELDS_NAME.VERIFY_PASSWORD]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.VERIFY_PASSWORD
                              )}
                              placeholder={"Enter Password"}
                              wrapperClass={"d-flex p-2"}
                              inputStyle={'{"width":"200%"}'}
                            />
                          </Col>
                          <Col sm="12">
                            <EzControlledDropDowntWithFloatingLabel
                              name={FORM_FIELDS_NAME.ROLES}
                              control={control}
                              rules={
                                getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE
                              }
                              isError={errors[FORM_FIELDS_NAME.ROLES]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.ROLES
                              )}
                              label="Roles"
                              optionLabel={"name"}
                              optionValue={"name"}
                              options={roleType}
                              placeholder={"Choose one"}
                              wrapperClass={"d-flex p-2"}
                              inputStyle={'{"width":"200%"}'}
                            />
                          </Col>
                          <Col sm="12">
                            <EzControlledDropDowntWithFloatingLabel
                              name={FORM_FIELDS_NAME.COUNTRY}
                              control={control}
                              rules={
                                getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE
                              }
                              isError={errors[FORM_FIELDS_NAME.COUNTRY]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.COUNTRY
                              )}
                              label="Country"
                              optionLabel={"value"}
                              optionValue={"key"}
                              options={countries}
                              placeholder={"Choose one"}
                              wrapperClass={"d-flex p-2"}
                              inputStyle={'{"width":"200%"}'}
                              onChange={(e) => handleCountryChange(e.value)}
                              disabled={countries.length === 0}
                            />
                          </Col>

                          <Col sm="12">
                            <EzControlledDropDowntWithFloatingLabel
                              name={FORM_FIELDS_NAME.PROVINCE}
                              control={control}
                              rules={
                                getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE
                              }
                              isError={errors[FORM_FIELDS_NAME.PROVINCE]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.PROVINCE
                              )}
                              label="Province"
                              optionLabel={"value"}
                              optionValue={"key"}
                              options={provinces}
                              placeholder={"Choose one"}
                              wrapperClass={"d-flex p-2"}
                              inputStyle={'{"width":"200%"}'}
                              onChange={(e) => handleProvinceChange(e.value)}
                              disabled={provinces.length === 0}
                            />
                          </Col>

                          <Col sm="12">
                            <EzControlledDropDowntWithFloatingLabel
                              name={FORM_FIELDS_NAME.CITY}
                              control={control}
                              rules={
                                getFormBaseValidationRule().DEFAULT
                                  .VALIDATION_RULE
                              }
                              isError={errors[FORM_FIELDS_NAME.CITY]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.CITY
                              )}
                              label="City"
                              optionLabel={"value"}
                              optionValue={"key"}
                              options={citys}
                              placeholder={"Choose one"}
                              wrapperClass={"d-flex p-2"}
                              inputStyle={'{"width":"200%"}'}
                              onChange={(e) => handleCityChange(e.value)}
                              disabled={citys.length === 0}
                            />
                          </Col>
                        </Row>
                      </Container>

                      <div className={"make-flex justify-content-center"}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div
                            style={{ margin: "15px 10px", minWidth: "100px" }}
                          >
                            <Button
                              label="Cancel"
                              type="button"
                              className="mt-2 p-button-raised"
                              onClick={navigateToUserSummaryScreen}
                            />
                          </div>
                          <div
                            style={{ margin: "15px 10px", minWidth: "100px" }}
                          >
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                trigger().then((isValid) => {
                                  if (isValid) {
                                    setStep(2);
                                  }
                                });
                              }}
                              label="Next"
                              className="mt-2 p-button-raised p-button-success"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </>
            )}

            {step === 2 && (
              <>
                <Container fluid className="card-box w-50">
                  <Row>
                    {permission?.map((item) => (
                      <Col sm={12} key={item?.label}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          // rules={
                          //   getFormBaseValidationRule().DEFAULT.VALIDATION_RULE
                          // }
                          optionLabel={"name"}
                          optionValue={"name"}
                          options={plasticType}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          wrapperClass={"d-flex p-2"}
                          placeholder={"Choose one"}
                          inputStyle={'{"width":"200%"}'}
                          disabled={true}
                        />
                      </Col>
                    ))}
                  </Row>
                </Container>
                <div className={"make-flex justify-content-center"}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                      <Button
                        label="Back"
                        type="button"
                        className="mt-2 p-button-raised"
                        onClick={(e) => {
                          e.preventDefault();
                          setStep(1);
                        }}
                      />
                    </div>
                    <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          trigger().then((isValid) => {
                            if (isValid) {
                              setStep(3);
                            }
                          });
                        }}
                        label="Next"
                        className="mt-2 p-button-raised p-button-success"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {step === 3 && (
              <>
                <Container fluid className="card-box w-75">
                  <b>Account Details:</b>
                  <Row className="mt-3">
                    <Col md={4}>
                      <p>
                        <b>Phone: </b> {watch(FORM_FIELDS_NAME.PHONE)}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Email: </b>
                        {watch(FORM_FIELDS_NAME.EMAIL)}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Password: </b>{" "}
                        {watch(FORM_FIELDS_NAME.ENTER_PASSWORD)}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Roles: </b> {watch(FORM_FIELDS_NAME.ROLES)}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Company: </b>
                        {watch(FORM_FIELDS_NAME.ENTER_PASSWORD)}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>City: </b> {watch(FORM_FIELDS_NAME.CITY)}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Country: </b> {watch(FORM_FIELDS_NAME.COUNTRY)}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <b>Province: </b> {watch(FORM_FIELDS_NAME.PROVINCE)}
                      </p>
                    </Col>
                  </Row>
                </Container>
                <Container fluid className="card-box w-75">
                  <PermissionScreen user={userPermissions} />
                </Container>

                <div className={"make-flex justify-content-center"}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                      <Button
                        label="Back"
                        type="button"
                        className="mt-2  p-button-raised"
                        onClick={(e) => {
                          e.preventDefault();
                          setStep(2);
                        }}
                      />
                    </div>
                    <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                      <Button
                        type="submit"
                        label="submit"
                        className="mt-2 p-button-raised p-button-success"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateUserComponent;
