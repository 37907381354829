export const FORM_FIELDS_NAME = {
 
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  ROLES: "role",
  EMAIL: "email",
  PHONE: "phone",
  CITY:"city",
  COUNTRY:"country",
  PROVINCE:"province",
  MANAGE_USERS: "manage_users",
  MANAGE_DASHBOARD: "manage_dashboard",
  VIEW_USERS: "view_users",
  MANAGE_FUEL_CARDS: "manage_fuel_cards",
  VIEW_FUEL_CARDS: "view_fuel_cards",
  VIEW_INVOICES: "view_invoices",
  MANAGE_MONEY_CODES: "manage_money_codes",
  VIEW_MONEY_CODES: "view_money_codes",
  APPROVE_MONEY_CODES: "approve_money_codes",
  VIEW_TRANSACTIONS: "view_transactions",
  MANAGE_REPORTS: "manage_reports",
  VIEW_REPORTS: "view_reports",
  MANAGE_TRUCKCARE: "manage_truckcare",
  ENTER_PASSWORD: "password",
  VERIFY_PASSWORD: "verify_password",
};
