
import { mstimeconversion, mstimeconversionUTC } from '../../../Services/utils';
import {
  INIT_TRANSACTIONS,
  FETCH_TRANSACTIONS_LIST,
  CREATE_TRANSACTIONS_RECORD,
  EDIT_TRANSACTIONS_RECORD,
  DELETE_TRANSACTIONS_RECORD,
  FETCH_TRANSACTIONS_DETAIL,
  TOAST,
} from '../../../constants/action-types/transactions';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';

const formFieldValueMap = {
   
  id:"",
  txn_card:"",
  txn_date:"",
  txn_amount:"",
  txn_price_per_unit:"",
  txn_total_cost:"",
  txn_station:"",
  txn_fuel_type:"",
  company:"",
  status:"",
  created_ip:"",
  modified_ip:"",
 
  txn_attr:{
    "1wk_age":"",
    "2wk_age":"",
    "3wk_age":"", 
    auth_id:"",
    balance_forward:"",
    broker_name:"",
    call_destination_city:"",
    call_origin_city:"",
    fed_exs_tax_rate:"",
    gst_amt:"",
    misc:"",
    net_price:"",
    odometer:"",
    product_code:"",
    product_description:"",
    provincial_fuel_tax_rate:"",
    pst_amt:"",
    qty:"",
    record_count:"",
    sales_type:"",
    station_address:"",
    station_number:"",
    station_province:"",
    total_adj:"",
    total_amount:"",
    total_gst_amt:"",
    total_pst_amt:"",
    total_qty:"",
    trailer_record_type:"",
    transaction_number:""
  }
  };
  
  const INITIAL_STATE = {
    transactionsListView: {},
    isLoading: false,
    error: null,
    formFieldValueMap,
    isCreateTransactionSuccess: false,
    isCreateTransactionError: false,
    isTransactionDetailError:false,
    isTransactionDetailSuccess: false,
    isEditTransactionSuccess: false,
    isEditTransactionError: false,
    isTransactionExportSuccess: false,
    isTransactionExportError: false,
  };
  

  const tranformToTransactionGridView = (data) => {
    const rows = data.map((item) => {
      const {
        id,
        authorization_code,
        transaction_datetime,
        card_number,
        station_number,
        company,
        bill_total,
        broker_name,
        site_number,
        bill_currency,
        site_city,
        state,
        vehicle_number,
        company_id,
        sub_transactions
      } = item;
  
      const transformedValues = {
        id,
        authorization_code,
        transaction_datetime,
        card_number,
        station_number,
        company:company?.name,
        bill_total:"$ "+bill_total,
        broker_name,
        site_number,
        bill_currency,
        site_city,
        state:state?.value,
        vehicle_number,
        company_id,
        sub_transactions:item?.sub_transactions
      };
      const decoratedValues = {};
      Object.keys(transformedValues).forEach((key) => {
        decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
      });
      return decoratedValues;
    });
    const subcolumns = [
      {
        colLabel: "Id",
        dataKey: "id",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: true,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Auth Code",
        dataKey: "auth_code",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Fuel Code",
        dataKey: "fuel_code",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "PPU",
        dataKey: "ppu_billed",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "FET",
        dataKey: "fet",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "GST",
        dataKey: "gst",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },{
        colLabel: "HST",
        dataKey: "hst",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
      ,{
        colLabel: "Local Tax",
        dataKey: "local_tax",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
      ,{
        colLabel: "PCT",
        dataKey: "pct",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
      ,{
        colLabel: "PFT",
        dataKey: "pft",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
      ,{
        colLabel: "PST",
        dataKey: "pst",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
      ,{
        colLabel: "QST",
        dataKey: "qst",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
      ,{
        colLabel: "Billed Amount",
        dataKey: "final_total_amount",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
    ];
    // txn_attr
    const columns = [
      {
        colLabel: "Auth Code",
        dataKey: "authorization_code",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Timestamp CST",
        dataKey: "transaction_datetime",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Petro Timestamp",
        dataKey: "petro",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Card Number",
        dataKey: "card_number",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Site Number",
        dataKey: "site_number",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "City",
        dataKey: "site_city",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Province/State",
        dataKey: "state",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      
      {
        colLabel: "Vehicle",
        dataKey:'vehicle_number',
        extraProps: setColumnDefaultExtraProps({
          isSortable: false,
            shouldFilter: false,
        }),
      },
      {
        colLabel: "Company",
        dataKey: "company_id",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "DBA",
        dataKey: "broker_name",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      } ,
      {
        colLabel: "Amount",
        dataKey: "bill_total",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      },
      {
        colLabel: "Currency",
        dataKey: "bill_currency",
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: "Search..."
        }
      }
      
    ];
  
    return {
      transactionsListView: { columns, rows,subcolumns },
    };
  };

  export const transactionReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case INIT_TRANSACTIONS:
        return { ...INITIAL_STATE, isLoadingPage: true };
  
      case FETCH_TRANSACTIONS_LIST.START:
      case CREATE_TRANSACTIONS_RECORD.START:
      case FETCH_TRANSACTIONS_DETAIL.START:
      case EDIT_TRANSACTIONS_RECORD.START:
        return { ...state, isLoading: true };
  
      case FETCH_TRANSACTIONS_LIST.SUCCESS: {
        let { page, content } = action.payload;
        content = content.map((item) => ({
          ...item,
          // transaction_datetime:mstimeconversionUTC(item?.transaction_datetime),
          // txn_attr:JSON.parse(item?.txn_attr)
         }));
        let resetState = {
          transactionsListView: null,
          paginationInfo: null,
          error: null,
          isLoading: false,
          isLoadingPage: false,
          isPageLevelError: false,
          isCreateTransactionSuccess: false,
          isCreateTransactionError: false,
          isTransactionListSuccess: false,
          iisTransactionListError: false,
          isEditTransactionSuccess: false,
          isEditManageCardError: false,
          isTransactionExportSuccess: false,
          isTransactionExportError: false,
        };
  
        if (!content) {
          return resetState;
        }
        const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
        const { transactionsListView } = tranformToTransactionGridView(content);
  
        return {
          ...resetState,
          transactionsListView,
          paginationInfo: {
            pageSize: size,
            totalRecords: totalElements,
            totalPages,
            pageNumber: number,
          },
        };
      }
  
      case FETCH_TRANSACTIONS_LIST.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          transactionsListView: {},
          isLoading: false,
          isLoadingPage: false,
          error,
          isPageLevelError: true,
          isTransactionDeletionSuccess: false,
          isTransactionDeletionError: false,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }
  
      case DELETE_TRANSACTIONS_RECORD.ERROR: {
        const { error } = action.payload;
  
        return {
          ...state,
          isLoading: false,
          isLoadingPage: false,
          error,
          isTransactionDeletionSuccess: false,
          isTransactionDeletionError: true,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }
  
      case DELETE_TRANSACTIONS_RECORD.SUCCESS: {
        return {
          ...state,
          isTransactionDeletionSuccess: true,
          shouldOpenDeleteConfirmationPopup: false,
        };
      }
  
      // case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      //   const shouldOpenDeleteConfirmationPopup = action.payload;
      //   return {
      //     ...state,
      //     shouldOpenDeleteConfirmationPopup,
      //     isTransactionDeletionError: false,
      //     isTransactionDeletionSuccess: false,
      //   };
      // }
  
     
      // case INIT_CREATE_NEW_TRANSACTIONS_SCREEN: {
      //   return { ...INITIAL_STATE };
      // }



      
    case FETCH_TRANSACTIONS_DETAIL.SUCCESS: {
      const temp = formFieldValueMap;
      debugger
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
          //   if (
          //     key === "cash_refresh_days" ||
          //     key === "weekly_vehicle_quantity_refresh_day" ||
          //     key === "non_highway_fuel_weekly_refresh_day"
          //   ) {
          //     const value = JSON.parse(action.payload[key]);
          //     if (value && typeof value === "object") {
          //       Object?.keys(temp[key])?.forEach((day) => {
          //         temp[key][day] = value?.hasOwnProperty(day)
          //           ? value[day]
          //           : false;
          //       });
          //     }
          //   } else {
          //     temp[key] = action.payload[key];
          //   }
          temp[key] = action.payload[key];
           }
        });
      }
      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isTransactionDetailError:false,
        isTransactionDetailSuccess: true,
      };
    }

    case FETCH_TRANSACTIONS_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isTransactionDetailError:true,
        isTransactionDetailSuccess: false,
      };
    }
  
      case CREATE_TRANSACTIONS_RECORD.ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          error,
          isLoading: false,
          isCreateTransactionSuccess: false,
          isCreateTransactionError: true,
        };
      }
      case CREATE_TRANSACTIONS_RECORD.SUCCESS: {
        return {
          ...state,
          isLoading: false,
          isCreateTransactionSuccess: true,
          isCreateTransactionError: false,
        };
      }
  
      case EDIT_TRANSACTIONS_RECORD.ERROR: {
        const { error } = action.payload;
        return {
          ...state,
          error,
          isLoading: false,
          isEditTransactionSuccess: false,
          isEditTransactionError: true,
        };
      }
  
      case EDIT_TRANSACTIONS_RECORD.SUCCESS: {
        return {
          ...state,
          isLoading: false,
          isEditTransactionSuccess: true,
          isEditTransactionError: false,
        };
      }
      case TOAST.RESET: {
        return {
          ...state,
          isCreateTransactionSuccess: false,
          isCreateTransactionError: false,
          isEditTransactionError: false,
          isEditTransactionSuccess: false,
          isTransactionDetailError: false,
        };
      }
      default:
        return state;
    }
  };