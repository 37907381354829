import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { placeholder } from '@babel/types';

const EzControlledDropDown = (props) => {
  const {
    control,
    name,
    shouldFilter,
    filterBy,
    options,
    optionLabel,
    optionValue,
    rules,
    onChange,
    disabled,
    placeholder,
    inputStyle
  } = props;

  return (
    <Controller
      name={name}
      
      control={control}
      rules={rules}
      render={({ field ,fieldState }) => (
        <Dropdown
          disabled={disabled}
          style={inputStyle ? JSON.parse(inputStyle) : {width:"100%"}}
          placeholder={placeholder}
          filter={shouldFilter}
          filterBy={filterBy || optionLabel}
          id={field.name}
          value={field.value}
          options={options}
          optionLabel={optionLabel}
          editable={true}
          optionValue={optionValue}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            field.onChange(e.value);
          }}
          className={classNames({
            'p-invalid': fieldState.invalid,
          })}
        />
      )}
    />
  );
};

EzControlledDropDown.defaultProps = {
  optionLabel: 'label',
  optionValue: 'value',
  shouldFilter: true,
  inputStyle:'',
};

export default EzControlledDropDown;
