import React from "react";
import { loadingsShow } from "../../Services/PostAPI";
import invoicesData from "./tableData.json";
import { APP_URLS } from "../../constants/urlConstants";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import Invoices from "../../components/Invoices";

const InvoicesScreen = () => {
  // loader none for now------------
  setTimeout(() => loadingsShow('none'), 1000)

  const history = useHistory()

  const createInvoice = () => {
    history.push(APP_URLS.INVOICES.ADD_INVOICES);
  };

  const editInvoice = (transactionId) => {
    console.log(transactionId);
    const path = generatePath(APP_URLS.INVOICES.EDIT_INVOICES, {
    transactionId,
    });
    history.push(path);
  };
  const invoicesProps = {
    createInvoice,
    editInvoice,
    ...invoicesData
  }
  
  return (
    <div>
      <Invoices
      {... invoicesProps}
      />
    </div>
  );
};

export default InvoicesScreen;
