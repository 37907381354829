export const FORM_FIELDS_NAME = {
  CARD_NUMBER: "card_number",
  COMPANY_NAME: "company_name",
  PLASTIC_TYPE:"plastic_type",

  DRIVER_NAME: "driver_name",
  DRIVER_ID: "driver_id",
  OTHER_ID:"other_id",
  COMMERCIAL_DRIVER_LICENSE:"commercial_driver_license",
  VEHICLE_ASSIGNED_TO_CARD:"vehicle_assigned_to_card",
  TRAILER_NUMBER:"trailer_number",

  CARD_TYPE:"card_type",
  CARD_STATUS:"card_status",
  CARD_GROUP:"card_group",
  ODOMETER:"odometer",
  TRIP_NUMBER:"trip_number",
  USAGE_ABOVE_VOLUME_LIMITS:"usage_above_volume_limits",
  
  CASH_ADVANCE_TYPE:"cash_advance_type",
  CASH_REFRESH_DAYS:"cash_refresh_days",
  REFRESHING_CASH_LIMIS:"refreshing_cash_limits",
  DAILY_CARD_LIMIT:"daily_card_limit",
  DAILY_CASH_LIMIT:"daily_cash_limit",
  DEBIT_DOLLAR_BALANCE:"debit_dollar_balance",
  REFRESHING_CASH_BALANCE:"refreshing_cash_balance",
  INCREMENT_DASH_CASH_AMOUNT:"increment_dash_cash_amount",
  PAY_CHECK_BALANCE:"pay_check_balance",
  DASH_CASH_BALANCE:"dash_cash_balance",
  DASH_CASH_MEMO:"dash_cash_memo",

  WEEKLY_PRODUCT_REFRESH:"weekly_product_refresh",
  WEEKLY_PRODUCT_REFRESH_MONEY_AMOUNT:"weekly_product_refresh_money_amount",
  WEEKLY_PRODUCT_BALANCE:"weekly_product_balance",
  WEEKLY_FUEL_QUANTITY_LIMIT:"weekly_fuel_quantity_limit",
  WEEKLY_VEHICLE_QUANTITY_REFRESH_DAY:"weekly_vehicle_quantity_refresh_day",
  WEEKLY_FUEL_QUANTITY_BALANCE:"weekly_fuel_quantity_balance",

  DAILY_VEHICLE_QUANTITY_LIMIT:"daily_vehicle_quantity_limit_in_gallons",
  TRANSACTION_FUEL_QUANTITY_LIMITS:"transaction_fuel_quantity_limits",

  NON_HIGHWAY_FUEL_LIMIT:"non_highway_fuel_limit",
  NON_HIGHWAY_FUEL_DAILY_QUANTITY_LIMIT:"non_highway_fuel_daily_quantity_limit",
  NON_HIGHWAY_FUEL_WEEKLY_QUANTITY_LIMIT:"non_highway_fuel_weekly_quantity_limit",
  NON_HIGHWAY_FUEL_WEEKLY_REFRESH_DAY:"non_highway_fuel_weekly_refresh_day",
  NON_HIGHWAY_FUEL_WEEKLY_BALANCE:"non_highway_fuel_weekly_balance", 
};
