import { APP_URLS } from '../../constants/urlConstants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getItems = (access, history, loggedInUserInfo, goToDashboard, handleLogout, fetchSwitchableAccounts) => {
  const items = [
    {
      label: 'Dashboard',
      command: goToDashboard,
    },
    {
      label: 'Company',
      visible: access?.companyMenu?.read,
      command: () => {  history.push(APP_URLS.COMPANY.HOME) },
    },
    {
      label: 'Users',
      visible: access?.userMenu?.read,
      command: () => {  history.push(APP_URLS.USERS.HOME) },
    },
    {
      label: 'Cards',
      visible: access?.managecardMenu?.read,
      command: () => {  history.push(APP_URLS.MANAGECARDS.HOME) },
    },     
    {
      label: 'Reporting',
      visible: access?.reportingMenu?.read,
      command: () => {  history.push(APP_URLS.REPORTING.HOME) },
    },
    {
      label: 'Transactions',
      visible: access?.transactionMenu?.read,
      command: () => {  history.push(APP_URLS.TRANSACTIONS.HOME) },
    },
    {
      label: 'Invoices',
      visible: access?.invoiceMenu?.read,
      command: () => {  history.push(APP_URLS.INVOICES.HOME) },
    },
    {
      label: 'Smart Buy',
      visible: access?.smartbuyMenu?.read,
      command: () => {  history.push(APP_URLS.SMARTBUY.HOME) },
    },
    {
      label: 'Truck Care',
      visible: access?.truckcareMenu?.read,
      command: () => {  history.push(APP_URLS.TRUCKCARE.HOME) },
    }
  ];
  
  const endItems = [{
    label: 'Profile',
    command: () => { 
    history.push(APP_URLS.USER_PROFILE.HOME)
    }
  },
  {
    label: 'Switch Account',
    disabled: !loggedInUserInfo?.isUserEligibleForAccountSwitch ?? true,
    command: fetchSwitchableAccounts
  },
  {
    label: 'Logout',
    command: () => { handleLogout() }
  }
]

  return [items, endItems];
}

  // TODO (Subham): Implement recursion here for multi level items
  export const getFilteredItems = (items) => {
  const newItems = []
  if (items) {
    items.forEach(item => {
      if (item.visible !== false) {

        if (item?.items?.length) {
          const newL2ITems = [];
          item.items.forEach(l2ITem => {
            if (l2ITem.visible !== false) {
              newL2ITems.push(l2ITem);
            }
          })
          item.items = newL2ITems;
        }
        
        newItems.push(item)
      }
    })
  }
  return newItems;
}

export const handleLogout = () => {
  cookies.remove('authorization');
  cookies.remove('first');
  cookies.remove('companyname');
  cookies.remove('companyImage');
  cookies.remove('profile_img');
  localStorage.getItem('menuAccess');
  localStorage.removeItem('menuAccess');
  sessionStorage.removeItem("switchAccountBanner");
  window.location.replace('/');
};