import Cookies from "universal-cookie";
import { post, put } from "axios";

import { getApiAsyn, deleteApi, baseUrl } from "../../PostAPI";
import { handleAPIError, APIError } from "../../common/errorHandler";
import { API_URL } from "../../../constants/urlConstants";
import { extractData } from "../../utils";
import { generateImageUrl, getDecoratedUrl } from "../../common/urlService";
import { asyncForEach } from "../../../utils/async";
import { getAllAttachment } from "../commonService";

const cookies = new Cookies();

export const fetchCompany = async (payload) => {
  const url = API_URL.PROFILE.FETCH_USER_COMPANY_INFO;
  try {
    let result = await getApiAsyn(url, cookies.get("authorization"));

    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
