import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FORM_FIELDS_NAME } from "./constant";
import { getFormBaseValidationRule } from "../../../utils/form/form-utils";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Col, Container, Row } from "react-bootstrap";
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import { Divider } from "primereact/divider";
import "./styles.css";
import { loadingsShow } from "../../../Services/PostAPI";
import EzControlledCalendarWithFloatingLabel from "../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import EzControlledInput from "../../../common/EzForm/EzControlledInput";
import Province from "../../../containers/common/Province";
import EzControlledInputWithFloatingLabel from "../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { RenderPageHeaderSection } from "../../../common/PageHeaderFF";
import { goBack } from "../../../utils/gotoback";

const RecordTypeOption = [
  { label: 'Positive', value: 'yes' },
  { label: 'Negative', value: 'no' },
];
const PCOption = [
  { label: 'PC1', value: '1' },
  { label: 'PC2', value: '2' },
];
const CreateTransaction = (props) => {
  const {
    isEdit,
    isView,
    isLoading,
    navigateToTransactionSummaryScreen,
    createTransaction,
    editTransaction,
    formFieldValueMap,
    isTransactionDetailSuccess,
    isCreateTransactionSuccess,
    isEditTransactionSuccess,
    isEditTransactionError,
    shouldBlockNavigation,
  } = props;
  console.log("check props of compoemnt", props);
  useEffect(() => {
    setTimeout(() => loadingsShow("none"), 1000);
  }, []);

  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  useEffect(() => shouldBlockNavigation(isDirty), [isDirty]);
  useEffect(() => {
    if (isCreateTransactionSuccess || isEditTransactionSuccess) {
      reset();
      navigateToTransactionSummaryScreen();
    }
  }, [isCreateTransactionSuccess]);

  useEffect(() => {
    if (isTransactionDetailSuccess) {
      reset({
        ...formFieldValueMap,
      });
    }
  }, [isTransactionDetailSuccess]);

  const onSubmit = (data) => {
    const {
      cash_refresh_days,
      non_highway_fuel_weekly_refresh_day,
      weekly_vehicle_quantity_refresh_day,
      ...rest
    } = data;

    const stringifiedData = {
      ...rest,
      cash_refresh_days: JSON.stringify(cash_refresh_days),
      non_highway_fuel_weekly_refresh_day: JSON.stringify(
        non_highway_fuel_weekly_refresh_day
      ),
      weekly_vehicle_quantity_refresh_day: JSON.stringify(
        weekly_vehicle_quantity_refresh_day
      ),
    };
    isEdit
      ? editTransaction({ id: data.id, data: stringifiedData })
      : createTransaction(stringifiedData);
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  // const renderPageHeaderSection = () => {
  //   return (
  //     <div className="make-relative sub-header">
  //       <div>
  //         <div>Edit Transaction</div>
  //         <div>Last Edit:12:43:00</div>
  //       </div>
  //       <div >
  //         <Button
  //           tooltip={"Back to Transaction summary"}
  //           tooltipOptions={{ position: "left" }}
  //           label={"List Of Transaction"}
  //           className={`p-button`}
  //           style={{ borderRadius: "10px"}}
  //           onClick={navigateToTransactionSummaryScreen}
  //         />
  //       </div>
  //     </div>
  //   );
  // };

  const renderRecordType = (name, label) => {
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={RecordTypeOption}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={label}
        labelClassName={'ml-2'}
      />
    );
  };

  const renderControlledInput = (
    name,
    label,
    type = 'text',
    rules = getFormBaseValidationRule().DEFAULT.DO_NOT_VALIDATE
  ) => {
    return (
      <EzControlledInputWithFloatingLabel
        type={type}
        name={name}
        control={control}
        label={label}
        rules={rules}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };
  const renderTransactionDate = () => {
    const name = 'txn_date';
    return (
      <EzControlledCalendarWithFloatingLabel
        name={name}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        control={control}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={'Transaction Date'}
      />
    );
  };

  const renderTransactionTime = () => {
    const name = 'txn_date';
    return (
      <EzControlledCalendarWithFloatingLabel
        name={name}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        control={control}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={'Transaction Time'}
        timeOnly={true}
        showSeconds={true}
      />
    );
  };


  const renderProvince = () => {
    const name = 'txn_attr.station_province';

    return (
      <Province
        name={name}
        control={control}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Station Province"
      />
    );
  };
  const renderProductCode = () => {
    const name = 'txn_attr.product_code';
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={PCOption}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Product Code"
        labelClassName={'ml-2'}
      />
    );
  };

  const renderSalesType = () => {
    const name = 'txn_attr.sales_type';
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        options={PCOption}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Sales type"
        labelClassName={'ml-2'}
      />
    );
  };


  return (
    <div className="paddingbox">
    <div
      className={classNames("containerboxform", { "p-disabled": isLoading })}
    >
      <RenderPageHeaderSection
title="Edit Transaction" 
timestamp={'Last updated at - 12:43:00 PM'} 
gottoback={navigateToTransactionSummaryScreen} 
tooltip="Back to Transaction summary" 
label="List Of Transaction"
/>
      <div className="form-demo">
       
      <div className="card" style={{ background: "#f3faf4BF" }}>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row>
                  <Col md="3">
                   {renderRecordType('trailer_record_type', 'Record Type')}
                  </Col>
                  <Col md="3">{renderControlledInput('txn_attr.card_number', 'Card Number')}</Col>
                  <Col md="3">
                    {renderTransactionDate()}
                  </Col>
                  <Col md="3">
                    {renderTransactionTime()}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.station_address', 'Station Address')}
                  </Col>
                  <Col md="3">

                    {renderProvince()}
                  </Col>
                  <Col md="3">{renderProductCode()} </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.product_description', 'Product Description')}
                  </Col>

                  <Col md="3">
                    {renderControlledInput('txn_attr.qty', 'QTY')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.net_price', 'Net Price')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.fed_exs_tax_rate', 'Fed Exs Tax Rate')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.provincial_fuel_tax_rate', 'Provincal Fuel Tax Rate')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.gst_amt', 'GST Amt')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.pst_amt', 'PST Amt')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_amount', 'Amt')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.misc', 'Misc')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.odometer', 'Odometer')}
                  </Col>
                  <Col md="3">{renderSalesType()} </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.transaction_number', 'Transaction Number')}
                  </Col>

                  <Col md="3">
                    {renderControlledInput('txn_attr.broker_name', 'Broker Name')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.station_number', 'Station Number')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.auth_id', 'Auth id')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.call_origin_city', 'Call Origin City')}
                  </Col>
                  <Col md="3">
                    {renderControlledInput('txn_attr.call_destination_city', 'Call Destination City')}
                  </Col>
                </Row>
               
                {renderDividerRow("Trailer")}
                
                <Row>

                <Col md="3">{ renderRecordType('txn_attr.trailer_record_type', 'Record Type')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.record_count', 'Record Count')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.total_qty', 'Total Qty')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.total_gst_amt', 'Total GST Amt')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.total_pst_amt', 'Total PST Amt')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.total_amount', 'Total Amount')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.balance_forward', 'Balance Forward')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.total_pay', 'Total Pay')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.total_adj', 'Total Adj')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.1wk_age', '1wk Age')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.2wk_age', '2wk Age')}</Col>
                <Col md="3">{renderControlledInput('txn_attr.3wk_age', '3wk Age')}</Col>
                </Row>
              </Container>
              <div
                className={"make-flex mt-3 mr-2"}
                style={{ flexDirection: "row-reverse", background: "white" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                    <Button
                      label="Cancel"
                      type="button"
                      className="p-btn-danger p-button-outlined mt-2"
                      onClick={goBack}
                    />
                  </div>
                  {/* <div style={{ margin: "15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label="Save"
                      className="mt-2 p-button-raised"
                    />
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderDividerRow = (label, align = "top", type = "solid") => {
  return (
    <Row className="mb-5 mt-5">
      <b className="ml-2">{label || ""}</b>
     
    </Row>
  );
};
export default CreateTransaction;
