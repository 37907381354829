import Cookies from "universal-cookie";
import { getApiAsyn } from "../../PostAPI";
import { handleAPIError, APIError } from "../../common/errorHandler";
import { getDecoratedUrl } from "../../common/urlService";
import { API_URL } from "../../../constants/urlConstants";

const cookies = new Cookies();

const getFetchCityUrl = (payload) => {
  return getDecoratedUrl({ url: API_URL.CITY.FETCH_CITY, payload });
};

export const fetchCitys = async (payload) => {
  const url = getFetchCityUrl(payload);
  try {
    let result = await getApiAsyn(url, cookies.get("authorization"));

    if (result?.error) {
      throw new APIError(result);
    }

    return { content: result };
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchCitysByProvince = async (payload) => {
  // const url = getFetchCityUrl(payload);
  const url = `${API_URL.COUNTRY.FETCH_COUNTRY}/${payload.countryId}/${API_URL.PROVINCE.FETCH_PROVINCES}/${payload.provinceId}/${API_URL.CITY.FETCH_CITY}`;
  try {
    let result = await getApiAsyn(url, cookies.get("authorization"));

    if (result?.error) {
      throw new APIError(result);
    }

    return { content: result };
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
