import React from "react";
import { Route } from "react-router-dom";
import CompanyScreen from "../../containers/CompanyScreen";

const ComapnyRoutes = (props) => {
  const { access, match } = props;
  let { create, read, update = false } = access;
  return (
    <>
      {
       !!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <CompanyScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )
      }
     
    </>
  );
};

export default ComapnyRoutes;
