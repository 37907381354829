import { DEFAULT_TOAST_LIFE } from "../../../common/EzToast";
import {
  INIT_USER,
  FETCH_USER_LIST_ERROR,
  FETCH_USER_LIST_START,
  FETCH_USER_LIST_SUCCESS,
  DELETE_USER_RECORD_START,
  DELETE_USER_RECORD_SUCCESS,
  DELETE_USER_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_USER_SCREEN,
  CREATE_USER_RECORD,
  EDIT_USER_RECORD,
  FETCH_USER_DETAIL,
  FETCH_ROLE,
  TOAST,
} from "../../../constants/action-types/user";
import { userService } from "../../../Services";
import { goBack } from "../../../utils/gotoback";

export const initializeUserScreen = () => ({ type: INIT_USER });

export const roleFetchStart = (payload) => ({
  type: FETCH_ROLE.START,
  payload,
});

export const roleFetchSuccess = (payload) => ({
  type: FETCH_ROLE.SUCCESS,
  payload,
});
export const roleFetchError = (payload) => ({
  type: FETCH_ROLE.ERROR,
  payload,
});

export const fetchRole = () => {
  return (dispatch) => {
    dispatch(roleFetchStart());
    userService.fetchRole().then((roleData) => {
      if (!roleData.isError) {
        dispatch(roleFetchSuccess(roleData));
      } else {
        dispatch(roleFetchError(roleData));
      }
    });
  };
};

export const userFetchStart = (payload) => ({
  type: FETCH_USER_LIST_START,
  payload,
});

export const userFetchSuccess = (payload) => ({
  type: FETCH_USER_LIST_SUCCESS,
  payload,
});

export const userFetchError = (payload) => ({
  type: FETCH_USER_LIST_ERROR,
  payload,
});

export const fetchUser = (payload) => {
  return (dispatch) => {
    dispatch(userFetchStart(payload));
    userService.fetchUser(payload).then((userData) => {
      if (!userData.isError) {
        dispatch(userFetchSuccess(userData));
      } else {
        dispatch(userFetchError(userData));
      }
    });
  };
};

export const userDetailFetchStart = (payload) => ({
  type: FETCH_USER_DETAIL.START,
  payload,
});

export const userDetailFetchSuccess = (payload) => ({
  type: FETCH_USER_DETAIL.SUCCESS,
  payload,
});

export const userDetailFetchError = (payload) => ({
  type: FETCH_USER_DETAIL.ERROR,
  payload,
});

export const fetchUserByUserId = (payload) => {
  return (dispatch) => {
    dispatch(userDetailFetchStart(payload));

    userService.fetchUserByUserId(payload).then((userData) => {
      if (!userData.isError) {
        dispatch(userDetailFetchSuccess(userData));
      } else {
        dispatch(userDetailFetchError(userData));
      }
    });
  };
};

// delete user
export const deleteUserRecordStart = () => ({
  type: DELETE_USER_RECORD_START,
});

export const deleteUserRecordSuccess = (payload) => ({
  type: DELETE_USER_RECORD_SUCCESS,
  payload,
});

export const deleteUserRecordError = (payload) => ({
  type: DELETE_USER_RECORD_ERROR,
  payload,
});

export const deleteUserRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteUserRecordStart());

    userService.deleteUserRecord({ userId: payload?.id }).then((data) => {
      if (!data.isError) {
        dispatch(deleteUserRecordSuccess());
        dispatch(fetchUser(payload?.fetchUserParams));
      } else {
        dispatch(deleteUserRecordError(data));
      }
    });
  };
};

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const createNewUserScreenInitStart = (payload) => ({
  type: INIT_CREATE_NEW_USER_SCREEN,
  payload,
});

export const initCreateNewUserScreen = (payload) => {
  return (dispatch) => {
    dispatch(createNewUserScreenInitStart());
  };
};

export const createUserRecordStart = () => ({
  type: CREATE_USER_RECORD.START,
});

export const createUserRecordSuccess = (payload) => ({
  type: CREATE_USER_RECORD.SUCCESS,
  payload,
});

export const createUserRecordError = (payload) => ({
  type: CREATE_USER_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createUser = (payload) => {
  return (dispatch) => {
    dispatch(createUserRecordStart());
    userService.createUserRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createUserRecordSuccess());
        goBack();
      } else {
        dispatch(createUserRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editUserRecordStart = (payload) => ({
  type: EDIT_USER_RECORD.START,
  payload,
});

export const editUserRecordSuccess = (payload) => ({
  type: EDIT_USER_RECORD.SUCCESS,
  payload,
});

export const editUserRecordError = (payload) => ({
  type: EDIT_USER_RECORD.ERROR,
  payload,
});

export const editUser = (payload) => {
  return (dispatch) => {
    dispatch(editUserRecordStart());
    userService.editUserRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editUserRecordSuccess());
        goBack();
      } else {
        dispatch(editUserRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const userFetchFilesByUserId = (payload) => async (dispatch) => {
  // dispatch(attachmentsFilesFetchStart(payload));
  // const {files} = await userService.fetchUserAttachmentByUserId(payload);
  // if(files) dispatch(attachmentsFilesFetchSuccess(files));
};

export const downloadAllAttachmentForUserId = (payload) => async (dispatch) => {
  // dispatch(downloadingAllAttachmentStart())
  // await userService.downloadAllAttachment(payload);
  // dispatch(downloadingAllAttachmentComplete());
};
