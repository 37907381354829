export const INIT_USER = "INIT_USER";
export const FETCH_USER_LIST_START = "FETCH_USER_LIST_START";
export const FETCH_USER_LIST_SUCCESS = "FETCH_USER_LIST_SUCCESS";
export const FETCH_USER_LIST_ERROR = "FETCH_USER_LIST_ERROR";

export const DELETE_USER_RECORD_START = "DELETE_USER_RECORD_START";
export const DELETE_USER_RECORD_SUCCESS = "DELETE_USER_RECORD_SUCCESS";
export const DELETE_USER_RECORD_ERROR = "DELETE_USER_RECORD_ERROR";

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  "SET_DELETE_CONFIRMATION_POPUP_VISIBILITY";

export const INIT_CREATE_NEW_USER_SCREEN = "INIT_CREATE_NEW_USER_SCREEN";

export const CREATE_USER_RECORD = {
  START: "CREATE_USER_RECORD_START",
  SUCCESS: "CREATE_USER_RECORD_SUCCESS",
  ERROR: "CREATE_USER_RECORD_ERROR",
};

export const EDIT_USER_RECORD = {
  START: "EDIT_USER_RECORD_START",
  SUCCESS: "EDIT_USER_RECORD_SUCCESS",
  ERROR: "EDIT_USER_RECORD_ERROR",
};

export const FETCH_USER_DETAIL = {
  START: "FETCH_USER_DETAIL_START",
  SUCCESS: "FETCH_USER_DETAIL_SUCCESS",
  ERROR: "FETCH_USER_DETAIL_ERROR",
};

export const TOAST = {
  RESET: "RESET_TOAST",
};

export const FETCH_ROLE ={
  START: "FETCH_ROLE_START",
  SUCCESS: "FETCH_ROLE_SUCCESS",
  ERROR: "FETCH_ROLE_ERROR",
}