import {
  INIT_COUNTRY,
  FETCH_COUNTRY_LIST_START,
  FETCH_COUNTRY_LIST_SUCCESS,
  FETCH_COUNTRY_LIST_ERROR,
} from "../../../constants/action-types/common/country";

import { countryService } from "../../../Services";

export const initializeCountryScreen = () => ({ type: INIT_COUNTRY });

export const countryFetchStart = () => ({ type: FETCH_COUNTRY_LIST_START });

export const countryFetchSuccess = (payload) => ({
  type: FETCH_COUNTRY_LIST_SUCCESS,
  payload,
});

export const countryFetchError = (payload) => ({
  type: FETCH_COUNTRY_LIST_ERROR,
  payload,
});

export const fetchCountries = (payload) => {
  return (dispatch) => {
    dispatch(countryFetchStart());

    countryService.fetchCountries(payload).then((countryData) => {
      if (!countryData.isError) {
        dispatch(countryFetchSuccess(countryData));
      } else {
        dispatch(countryFetchError(countryData));
      }
    });
  };
};
