import React from 'react';
import { classNames } from 'primereact/utils';
import PropTypes from 'prop-types';

import EzControlledDropDown from '../';

const EzControlledDropDowntWithFloatingLabel = (props) => {
  const {
    control,
    name,
    rules,
    options,
    optionLabel,
    optionValue,
    onChange,
    labelClassName,
    label,
    id,
    filter,
    errorMsg,
    isError,
    disabled,
    wrapperClass,
    placeholder,
    inputStyle
  } = props;

  const getLabelClassName = () => {
    return classNames({
      'p-error': isError,
      labelClassName,
    });
  };

  return (
    <div className="field">
      {/* ="p-float-label" */}
      <span className={wrapperClass}>
        <label htmlFor={name} className={getLabelClassName()}>
          {label}
          {rules?.required ? <span style={{ color: 'red' }}>*</span> : null}
        </label>

        <EzControlledDropDown
          disabled={disabled}
          placeholder={placeholder}
          filter={filter}
          name={name}
          id={id}
          optionLabel={optionLabel}
          optionValue={optionValue}
          onChange={onChange}
          control={control}
          rules={rules}
          options={options}
          inputStyle={inputStyle}
        />
      </span>
      {errorMsg}
    </div>
  );
};

EzControlledDropDowntWithFloatingLabel.defaultProps = {
  labelClassName: '',
  filter: false,
  optionLabel: 'label',
  optionValue: 'value',
  wrapperClass: '',
  inputStyle:'',
};

EzControlledDropDowntWithFloatingLabel.propTypes = {
  labelClassName: PropTypes.string,
  filter: PropTypes.bool,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
};

export default EzControlledDropDowntWithFloatingLabel;
