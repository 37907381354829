import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import CreateUserComponent from "../../../components/User/CreateUser";
import { APP_URLS } from "../../../constants/urlConstants";
import {
  initCreateNewUserScreen,
  createUser,
  fetchRole,
  editUser,
  fetchUserByUserId,
} from "../../../Redux/Actions/user";
import { fetchCountries } from "../../../Redux/Actions/country";
import { fetchProvincesByCountry } from "../../../Redux/Actions/province";
import { fetchCitysByProvince } from "../../../Redux/Actions/city";
import { useEffect, useState } from "react";
import { setIsformDirty } from "../../../Redux/Actions/common/form";

const CreateUserContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createUser,
    editUser,
    countries,
    provinces,
    citys,
    fetchCitysByProvince,
    fetchProvincesByCountry,
    fetchCountries,
    role,
    userRole,
    isLoading,
    isCreateUserSuccess,
    isCreateUserError,
    isUserDetailSuccess,
    isUserDetailError,
    isEditUserSuccess,
    isEditUserError,
    isEdit,
    formFieldValueMap,
    fetchUserByUserId,
    shouldBlockNavigation,
  } = props;
  const { id } = props.match.params;
  const UserId = id;
  useEffect(() => {
    fetchCountries();
    // fetchRole();
    if (isEdit) {
      fetchRole();
      fetchUserByUserId({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const navigateToUserSummaryScreen = () => {
    history.push(APP_URLS.USERS.HOME);
  };
  const [countryId, setCountryId] = useState(0);
  const [provinceId, setProvinceId] = useState(0);
  const [cityId, setCityId] = useState(0);

  const handleCountryChange = (selectedCountry) => {
    setCountryId(selectedCountry);
    let payload = { countryId: selectedCountry };
    fetchProvincesByCountry(payload);
  };

  const handleProvinceChange = (selectedProvince) => {
    setProvinceId(selectedProvince);
    let payload = { countryId: countryId, provinceId: selectedProvince };
    fetchCitysByProvince(payload);
  };

  const handleCityChange = (selectedCity) => {
    setCityId(selectedCity);
    let payload = {
      countryId: countryId,
      provinceId: provinceId,
      cityId: cityId,
    };
    // if need get by id city then call
  };

  const createUserProps = {
    createUser,
    editUser,
    isLoading,
    isEdit,
    isUserDetailSuccess,
    isCreateUserSuccess,
    isEditUserSuccess,
    isEditUserError,
    formFieldValueMap,
    UserId,
    shouldBlockNavigation,
    countries,
    provinces,
    citys,
    onCountryChange: handleCountryChange,
    onProvinceChange: handleProvinceChange,
    onCityChange: handleCityChange,
    navigateToUserSummaryScreen,
  };

  const getToastProps = () => {
    if (isCreateUserSuccess || isEditUserSuccess) {
      const toastTitle = isEdit
        ? "User Updated Successfully"
        : "User Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (isCreateUserError || isUserDetailError || isEditUserError) {
      let toastTitle = "Error while Creating User";
      if (isEditUserError) {
        toastTitle = "Error while updating User";
      } else if (isUserDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateUserComponent {...createUserProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromUsersReducer(state, "isLoading"),
    isRole: selectFromUsersReducer(state, "role"),
    isFetchingCity: selectFromCityReducer(state, "isFetchingCity"),
    errorInFetchingCity: selectCityReducer(state, "errorInFetchingCity"),
    isFetchingCountry: selectFromCountryReducer(state, "isFetchingCountry"),
    isCreateUserSuccess: selectFromUsersReducer(state, "isCreateUserSuccess"),
    isCreateUserError: selectFromUsersReducer(state, "isCreateUserError"),
    isUserDetailSuccess: selectFromUsersReducer(state, "isUserDetailSuccess"),
    isUserDetailError: selectFromUsersReducer(state, "isUserDetailError"),
    isEditUserSuccess: selectFromUsersReducer(state, "isEditUserSuccess"),
    isEditUserError: selectFromUsersReducer(state, "isEditUserError"),

    countries: state?.countryReducer?.countries ?? [],
    provinces: state?.provinceReducer?.provinces ?? [],
    citys: state?.cityReducer?.citys ?? [],
    formFieldValueMap: selectFromUsersReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateNewUserScreen: () => dispatch(initCreateNewUserScreen()),
    fetchRole: () => dispatch(fetchRole()),

    fetchCitysByProvince: (payload) => dispatch(fetchCitysByProvince(payload)),
    fetchProvincesByCountry: (payload) =>
      dispatch(fetchProvincesByCountry(payload)),
    fetchCountries: () => dispatch(fetchCountries()),

    fetchUserByUserId: (payload) => dispatch(fetchUserByUserId(payload)),
    createUser: (payload) => dispatch(createUser(payload)),
    editUser: (payload) => dispatch(editUser(payload)),
    shouldBlockNavigation: (isFormDirty) =>
      dispatch(setIsformDirty(isFormDirty)),
  };
};

const selectFromUsersReducer = (state, path) => {
  return selectUsersReducer(state)[path];
};

const selectFromCityReducer = (state, path) => {
  return selectCityReducer(state)[path];
};

const selectUsersReducer = ({ userReducer }) => {
  return userReducer;
};

const selectCityReducer = ({ cityReducer }) => {
  return cityReducer;
};

const selectFromCountryReducer = ({ countryReducer }) => {
  return countryReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserContainer);
