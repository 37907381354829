import React, { Component } from 'react';

import NewHeader from './common/Header/NewHeader';
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import Cookies from 'universal-cookie';
import Routing from './Router/Routing';

const cookies = new Cookies();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCookiesSet: cookies.get('authorization'),
    };
  }

  SwithToLogin = () => {
    this.setState({
      isCookiesSet: cookies.get('authorization'),
    });
  };
  render() {
    if (
      this.state.isCookiesSet !== null &&
      this.state.isCookiesSet !== undefined
    ) {
      return (
        <>
          <div>
            <NewHeader />
            <div className="comoncantainer">
              <Routing switchHeader={this.SwithToLogin} />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Routing switchHeader={this.SwithToLogin} />
        </div>
      );
    }
  }
}

export default App;


