import { connect } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

import { EzToast, TOAST_SEVERITY } from "../../../common/EzToast";
import CreateManageCardComponent from "../../../components/Managecards/CreateManagecards";
import { APP_URLS } from "../../../constants/urlConstants";
import {
  initCreateNewManageCardScreen,
  createManageCard,
  editManageCard,
  fetchManageCardByManageCardId,
} from "../../../Redux/Actions/manage-card";
import { useEffect } from "react";
import { setIsformDirty } from "../../../Redux/Actions/common/form";

const CreateManageCardContainer = (props) => {
  console.log("checkprops on screen ", props);
  const {
    history,
    createManageCard,
    editManageCard,
    isLoading,
    isCreateManageCardSuccess,
    isCreateManageCardError,
    isManageCardDetailSuccess,
    isManageCardDetailError,
    isEditManageCardSuccess,
    isEditManageCardError,
    isEdit,
    isView,
    formFieldValueMap,
    fetchManageCardByManageCardId,
    shouldBlockNavigation,
  } = props;

  const { id } = props.match.params;
  const ManageCardId = id;

  useEffect(() => {
    if (isEdit || isView) {
      fetchManageCardByManageCardId({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isView]);

  const navigateToCardManageSummaryScreen = () => {
    history.push(APP_URLS.MANAGECARDS.HOME);
  };

  const createManageCardProps = {
    createManageCard,
    editManageCard,
    isLoading,
    isEdit,
    isManageCardDetailSuccess,
    isCreateManageCardSuccess,
    isEditManageCardSuccess,
    isEditManageCardError,
    formFieldValueMap,
    ManageCardId,
    shouldBlockNavigation,
    navigateToCardManageSummaryScreen,
  };

  const getToastProps = () => {
    if (isCreateManageCardSuccess || isEditManageCardSuccess) {
      const toastTitle = isEdit
        ? "ManageCard Updated Successfully"
        : "ManageCard Created Successfully";
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
      };
    }

    if (
      isCreateManageCardError ||
      isManageCardDetailError ||
      isEditManageCardError
    ) {
      let toastTitle = "Error while Creating ManageCard";
      if (isEditManageCardError) {
        toastTitle = "Error while updating ManageCard";
      } else if (isManageCardDetailError) {
        toastTitle =
          "Error while performing operation. Please refresh your browser";
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
      };
    }
  };

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: "6px" }} />;
  };

  return (
    <>
      {isLoading && renderProgressBar()}
      <EzToast {...getToastProps()} />
      <CreateManageCardComponent {...createManageCardProps} />;
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  console.log("mapState", state);
  return {
    isLoading: selectFromManageCardsReducer(state, "isLoading"),
    isCreateManageCardSuccess: selectFromManageCardsReducer(
      state,
      "isCreateManageCardSuccess"
    ),
    isCreateManageCardError: selectFromManageCardsReducer(
      state,
      "isCreateManageCardError"
    ),
    isManageCardDetailSuccess: selectFromManageCardsReducer(
      state,
      "isManageCardDetailSuccess"
    ),
    isManageCardDetailError: selectFromManageCardsReducer(
      state,
      "isManageCardDetailError"
    ),
    isEditManageCardSuccess: selectFromManageCardsReducer(
      state,
      "isEditManageCardSuccess"
    ),
    isEditManageCardError: selectFromManageCardsReducer(
      state,
      "isEditManageCardError"
    ),
    formFieldValueMap: selectFromManageCardsReducer(state, "formFieldValueMap"),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initCreateNewManageCardScreen: () =>
      dispatch(initCreateNewManageCardScreen()),
    fetchManageCardByManageCardId: (payload) =>
      dispatch(fetchManageCardByManageCardId(payload)),
    createManageCard: (payload) => dispatch(createManageCard(payload)),
    editManageCard: (payload) => dispatch(editManageCard(payload)),
    shouldBlockNavigation: (isFormDirty) =>
      dispatch(setIsformDirty(isFormDirty)),
  };
};

const selectFromManageCardsReducer = (state, path) => {
  return selectManageCardsReducer(state)[path];
};

const selectManageCardsReducer = ({ manageCardReducer }) => {
  return manageCardReducer;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateManageCardContainer);
