import React from "react";
import { loadingsShow } from "../../Services/PostAPI";
import Reporting from "../../components/Reporting";
import transactionsData from "./tableData.json";
import { APP_URLS } from "../../constants/urlConstants";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import SmartBuy from "../../components/SmartBuy";

const SmartBuyScreen = () => {
  // loader none for now------------
  setTimeout(() => loadingsShow('none'), 1000)

  const history = useHistory()

  const createTransaction = () => {
    history.push(APP_URLS.TRANSACTIONS.ADD_TRANSACTION);
  };

  const editTransaction = (transactionId) => {
    console.log(transactionId);
    const path = generatePath(APP_URLS.TRANSACTIONS.EDIT_TRANSACTION, {
    transactionId,
    });
    history.push(path);
  };
  const transactionProps = {
    createTransaction,
    editTransaction,
    ...transactionsData
  }


  return (
    <div>
      <SmartBuy
      {... transactionProps}
      />
    </div>
  );
};

export default SmartBuyScreen;
