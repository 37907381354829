import React from 'react';
import { Dialog } from 'primereact/dialog';
import EzProgressBar from './EzProgressBar';

const EzDialog =   props => {
const { children, header, width, height, onHide, visible, maximizable, isLoading, footer } = props;

const splitLength = (length) => {
    const index = length.split('').findIndex( char => isNaN(char) )
    return [length.substring(0,index), length.substring(index)];
}

const calculateHeightAndWidth = (dialogHeight, dialogWidth) => {
    const [heightNumber, heightUnit] = splitLength(dialogHeight);
    const [widthNumber, widthUnit] = splitLength(dialogWidth);
    
    return {
        dialogChildheight: (heightNumber - 40).toLocaleString() + heightUnit,
        dialogChildwidth: (widthNumber -10).toLocaleString() + widthUnit 
    }
}        

const { dialogChildheight, dialogChildwidth } = calculateHeightAndWidth(height, width);
return (
<Dialog
    onHide = {onHide}
    visible= {visible}
    header = {header}
    contentStyle = {{ height }}
    style= {{ width}}
    maximizable = {maximizable}
    footer={footer}
    >
    { isLoading && <div style={{display: 'flex',
                height: dialogChildheight,
                width: dialogChildwidth,
                justifyContent: 'center',
                alignItems: 'center'}}>
        <EzProgressBar />
    </div>}
    { children }
    </Dialog>);
}   

EzDialog.defaultProps = {
    height: '500px',
    width: '50vw',
    maximizable: true,
    visible: true,
    isLoading:false,
    footer:null
}

EzDialog.defaultProps = {
    height: '500px',
    width: '50vw',
    maximizable: true,
    visible: true
}

export default EzDialog