import {
  INIT_CITY,
  FETCH_CITY_LIST_START,
  FETCH_CITY_LIST_SUCCESS,
  FETCH_CITY_LIST_ERROR,
  FETCH_CITY_LIST_BY_PROVINCE,
} from "../../../constants/action-types/common/city";

import { cityService } from "../../../Services";

export const initializeCityScreen = () => ({ type: INIT_CITY });

export const cityFetchStart = (payload) => ({
  type: FETCH_CITY_LIST_START,
  payload,
});

export const cityFetchSuccess = (payload) => ({
  type: FETCH_CITY_LIST_SUCCESS,
  payload,
});

export const cityFetchError = (payload) => ({
  type: FETCH_CITY_LIST_ERROR,
  payload,
});

export const fetchCitys = (payload) => {
  return (dispatch) => {
    dispatch(cityFetchStart(payload));

    cityService.fetchCitys(payload).then((cityData) => {
      if (!cityData.isError) {
        dispatch(cityFetchSuccess(cityData));
      } else {
        dispatch(cityFetchError(cityData));
      }
    });
  };
};

export const fetchCitysByProvinceStart = (payload) => ({
  type: FETCH_CITY_LIST_BY_PROVINCE.START,
  payload,
});

export const fetchCitysByProvinceSuccess = (payload) => ({
  type: FETCH_CITY_LIST_BY_PROVINCE.SUCCESS,
  payload,
});

export const fetchCitysByProvinceError = (payload) => ({
  type: FETCH_CITY_LIST_BY_PROVINCE.ERROR,
  payload,
});

export const fetchCitysByProvince = (payload) => {
  return (dispatch) => {
    dispatch(fetchCitysByProvinceStart(payload));
    cityService.fetchCitysByProvince(payload).then((cityData) => {
      if (!cityData.isError) {
        dispatch(fetchCitysByProvinceSuccess(cityData));
      } else {
        dispatch(fetchCitysByProvinceError(cityData));
      }
    });
  };
};
