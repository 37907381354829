import {
  INIT_USER,
  FETCH_USER_LIST_ERROR,
  FETCH_USER_LIST_START,
  FETCH_USER_LIST_SUCCESS,
  DELETE_USER_RECORD_START,
  DELETE_USER_RECORD_SUCCESS,
  DELETE_USER_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_USER_SCREEN,
  CREATE_USER_RECORD,
  EDIT_USER_RECORD,
  FETCH_USER_DETAIL,
  TOAST,
  FETCH_ROLE,
} from "../../../constants/action-types/user";
import { buildName } from "../../../utils/string";
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from "../../../utils/table/gridUtils";

const formFieldValueMap = {};



const tranformToUserListGridView = (data) => {
  const rows = data.map((item) => {
    const {
      id,
      first_name,
      last_name,
      email,
      phone,
      company,
      role,
    } = item;

    const transformedValues = {
      id,
      first_name,
      last_name,
      email,
      phone,
      company:company?.name,
      role:role?.name,
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: "Email",
      dataKey: "email",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search..."
      }
    },
    {
      colLabel: "First Name",
      dataKey: "first_name",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search..."
      }
    },
    {
      colLabel: "Last Name",
      dataKey: "last_name",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search..."
      }
    },
    {
      colLabel: "Company",
      dataKey:'company',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
          shouldFilter: false,
      }),
    },
    {
      colLabel: "Phone",
      dataKey: "phone",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search..."
      }
    },
    {
      colLabel: "Role",
      dataKey: "role",
      extraProps: {
        isSortable: false,
        isDateField: false,
        shouldFilter: false,
        hidden: false,
        showFilterMenu: false,
        filterPlaceholder: "Search..."
      }
    }
  ];

  return {
    userList: { columns, rows },
  };
};

const INITIAL_STATE = {
  userList: [],
  role: [],
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateUserSuccess: false,
  isCreateUserError: false,
  isUserDetailSuccess: false,
  isUserDetailError: false,
  isEditUserSuccess: false,
  isEditUserError: false,
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT_USER:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_USER_LIST_START:
    case CREATE_USER_RECORD.START:
    case DELETE_USER_RECORD_START:
    case FETCH_USER_DETAIL.START:
    case EDIT_USER_RECORD.START:
    case FETCH_ROLE.START:
      return { ...state, isLoading: true };
    case FETCH_ROLE.SUCCESS: {
      // console.log(action.payload);
      // const { pageable, content } = action.payload;
      return { ...state, role: action.payload, isLoading: false };
    }
    case FETCH_ROLE.ERROR: {
      // console.log(action.payload);
      // const { pageable, content } = action.payload;
      return { ...state, error: action.payload, isLoading: false };
    }

    case FETCH_USER_LIST_SUCCESS: {
      const { page, _embedded } = action.payload;
      const { content} =_embedded;
     
      let resetState = {
        userList: [],
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateUserSuccess: false,
        isCreateUserError: false,
      };

      if (!content) {
        return resetState;
      }
       const { number, size,total_elements, total_pages: total_pages } = page;
       const { userList } = tranformToUserListGridView(content);
      return {
        ...resetState,
        userList,
        paginationInfo: {
          pageSize: size,
          totalRecords: total_elements,
          totalPages: total_pages,
          pageNumber:number,
        },
      };
    }

    case FETCH_USER_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        userList: [],
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isUserDeletionSuccess: false,
        isUserDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_USER_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isUserDeletionSuccess: false,
        isUserDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_USER_RECORD_SUCCESS: {
      return {
        ...state,
        isUserDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isUserDeletionError: false,
        isUserDeletionSuccess: false,
      };
    }

    case INIT_CREATE_NEW_USER_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_USER_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateUserSuccess: false,
        isCreateUserError: true,
      };
    }
    case CREATE_USER_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateUserSuccess: true,
        isCreateUserError: false,
      };
    }

    case FETCH_USER_DETAIL.SUCCESS: {
      const temp = { ...state.formFieldValueMap };
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
            if (
              key === "cash_refresh_days" ||
              key === "weekly_vehicle_quantity_refresh_day" ||
              key === "non_highway_fuel_weekly_refresh_day"
            ) {
              const value = JSON.parse(action.payload[key]);
              if (value && typeof value === "object") {
                Object?.keys(temp[key])?.forEach((day) => {
                  // temp[key] = temp[key] ?? {};
                  temp[key][day] = value?.hasOwnProperty(day)
                    ? value[day]
                    : false;
                });
              }
            } else {
              temp[key] = action.payload[key];
            }
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isUserDetailSuccess: true,
      };
    }

    case FETCH_USER_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isUserDetailSuccess: false,
        isUserDetailError: true,
      };
    }

    case EDIT_USER_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditUserSuccess: false,
        isEditUserError: true,
      };
    }

    case EDIT_USER_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditUserSuccess: true,
        isEditUserError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateUserSuccess: false,
        isCreateUserError: false,
        isEditUserError: false,
        isEditUserSuccess: false,
        isUserDetailError: false,
      };
    }
    default:
      return state;
  }
};
