import { getApiAsyn } from "../Services/PostAPI";

export const getScreenPermissionInfo = (res, permissionKey) => {
  return res.find((item) => item.code === permissionKey);
};

export const getMenuAccess = async (token) => {
  let res = await getApiAsyn(`screens`, token);
  let ord = res.filter((item) => item.code === "ORD");
  let db = res.filter((item) => item.code === "DB");
  // let mc=res.filter((item)=> item.code === 'MC')

  let access = {
    orderMenu: ord[0],
    dashboardMenu: db[0],
    managecardMenu: getScreenPermissionInfo(res, "FCM"),
    userMenu:getScreenPermissionInfo(res,"FU"),
    transactionMenu:getScreenPermissionInfo(res,"FT"),
    invoiceMenu:getScreenPermissionInfo(res,"FI"),
    employeeMenu: getScreenPermissionInfo(res, "EMP"),
    companyMenu: getScreenPermissionInfo(res, "FC"),
    reportingMenu: getScreenPermissionInfo(res, "FRPT"),
    smartbuyMenu: getScreenPermissionInfo(res, "FSB"),
    truckcareMenu: getScreenPermissionInfo(res, "FTC"),
    userprofileMenu: getScreenPermissionInfo(res, "FTC")
  };
  if(access?.companyMenu===undefined){
    access={
      ...access,
      companyMenu:{
        read:false,
        create:false,
        update:false,
        delete:false
      }
    }
  }
  if(access?.managecardMenu===undefined){
    access={
      ...access,
      managecardMenu:{
        read:false,
        create:false,
        update:false,
        delete:false
      }
    }
  }
  if(access?.userMenu===undefined){
    access={
      ...access,
      userMenu:{
        read:false,
        create:false,
        update:false,
        delete:false
      }
    }
  }
  if(access?.transactionMenu===undefined){
    access={
      ...access,
      transactionMenu:{
        read:false,
        create:false,
        update:false,
        delete:false
      }
    }
  }
  if(access?.invoiceMenu===undefined){
    access={
      ...access,
      invoiceMenu:{
        read:false,
        create:false,
        update:false,
        delete:false
      }
    }
  }
  if(access?.employeeMenu===undefined){
    access={
      ...access,
      employeeMenu:{
        read:false,
        create:false,
        update:false,
        delete:false
      }
    }
  }
  if(access?.reportingMenu===undefined){
    access={
      ...access,
      reportingMenu:{
        read:false,
        create:false,
        update:false,
        delete:false
      }
    }
  }
  if(access?.smartbuyMenu===undefined){
    access={
      ...access,
      smartbuyMenu:{
        read:false,
        create:false,
        update:false,
        delete:false
      }
    }
  }
  if(access?.truckcareMenu===undefined){
    access={
      ...access,
      truckcareMenu:{
        read:false,
        create:false,
        update:false,
        delete:false
      }
    }
  }
  if(access?.userprofileMenu===undefined){
    access={
      ...access,
      userprofileMenu:{
        read:true,
        create:true,
        update:true,
        delete:true
      }
    }
  }
  
  localStorage.setItem("menuAccess", JSON.stringify(access));
};
