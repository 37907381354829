import React from "react";
import { Route } from "react-router-dom";

import { APP_URLS } from "../../constants/urlConstants";
import CreateTransactions from "../../containers/TransactionsScreen/CreateTransactions";
import NotFound from "../../NotFound/NotFound";
import SmartBuyScreen from "../../containers/SmartBuyScreen";

const SmartBuyRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update } = access;
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <SmartBuyScreen {...routeProps}  />
          )}
        />)}
      {!!create && (
        <Route
          path={APP_URLS.SMARTBUY.ADD_SMARTBUY}
          component={CreateTransactions}
        />
        )}
      {!!update && (
        <Route
          path={APP_URLS.SMARTBUY.EDIT_SMARTBUY}
          render={(routeProps) => (
            <CreateTransactions {...routeProps} isEdit={true} />
          )}
        />
        )}
    
    </>
  );
};

export default SmartBuyRoutes;
