import { Button } from "primereact/button";

export const RenderPageHeaderSection = ({title, timestamp, gottoback, tooltip, label}) => {
    return (
      <div className="make-relative ">
       
       { gottoback &&  <div className="sub-header">
          <Button
            tooltip={tooltip ? tooltip : ''}
            tooltipOptions={{ position: "left" }}
            label={label ? label : ''}
            className={`p-button`}
            style={{ borderRadius: "10px"}}
            onClick={gottoback}
          />
        </div>}
        <div className="pagetitle">
            {title && <div className="page-header">{title}</div>}
            {timestamp && <div className="page-timestamp">{timestamp}</div>}
        </div>
      </div>
    );
  };