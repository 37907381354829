import React from "react";
import { Container, Table } from "react-bootstrap";

const PermissionScreen = ({ user }) => {
  console.log(user);
  const { company, permissions } = user;

  const chunkPermissions = (permissions) => {
    const pairs = Object.entries(permissions);
    const chunked = [];
    for (let i = 0; i < pairs.length; i += 3) {
      chunked.push(pairs.slice(i, i + 3));
    }
    return chunked;
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100%" }}
    >
      <Container fluid className="p-0">
        <div className="permission-compmay-title">{company}</div>
        <Table bordered>
          <tbody>
            {chunkPermissions(permissions).map((chunk, index) => (
              <tr key={index}>
                {chunk.map(([key, value], index) => (
                  <td
                    className="table-content"
                    key={index}
                    style={{ textAlign: "left" }}
                  >
                    {key}: <strong>{value}</strong>
                  </td>
                ))}

                {chunk.length < 3 && (
                  <td
                    colSpan={3 - chunk.length}
                    style={{ padding: "5px" }}
                  ></td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default PermissionScreen;
