export const INIT_PROVINCE = 'INIT_PROVINCE';
export const FETCH_PROVINCE_LIST_START = 'FETCH_PROVINCE_LIST_START';
export const FETCH_PROVINCE_LIST_SUCCESS = 'FETCH_PROVINCE_LIST_SUCCESS';
export const FETCH_PROVINCE_LIST_ERROR = 'FETCH_PROVINCE_LIST_ERROR';

export const FETCH_PROVINCE_LIST_BY_COUNTRY={
    START:"FETCH_PROVINCE_LIST_BY_COUNTRY_START",
    SUCCESS:"FETCH_PROVINCE_LIST_BY_COUNTRY_SUCCESS",
    ERROR:'FETCH_PROVINCE_LIST_BY_COUNTRY_ERROR'
}

export const FETCH_PROVINCE_DETAILS={
    START:"FETCH_PROVINCE_DETAILS_START",
    SUCCESS:"FETCH_PROVINCE_DETAILS_SUCCESS",
    ERROR:"FETCH_PROVINCE_DETAILS_ERROR"
}
