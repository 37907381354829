import {
  INIT_MANAGE_CARD,
  FETCH_MANAGE_CARD_LIST_ERROR,
  FETCH_MANAGE_CARD_LIST_START,
  FETCH_MANAGE_CARD_LIST_SUCCESS,
  DELETE_MANAGE_CARD_RECORD_START,
  DELETE_MANAGE_CARD_RECORD_SUCCESS,
  DELETE_MANAGE_CARD_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_MANAGE_CARD_SCREEN,
  CREATE_MANAGE_CARD_RECORD,
  EDIT_MANAGE_CARD_RECORD,
  FETCH_MANAGE_CARD_DETAIL,
  TOAST,
  EXPORT_MANAGE_CARD
} from "../../../constants/action-types/manageCard";
import { downloadCSV } from "../../../utils/download";

const formFieldValueMap = {
  id: null,
  card_issue_date: null,
  status: false,
  card_group: null,
  card_status: null,
  card_type: null,
  cash_advance_type: null,
  cash_refresh_days: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    na: false,
  },
  commercial_driver_license: null,
  company: null,
  company_name: null,
  daily_card_limit: null,
  daily_vehicle_quantity_limit_in_gallons: null,
  dash_cash_balance: null,
  dash_cash_memo: null,
  debit_dollar_balance: null,
  driver_id: null,
  driver_name: null,
  increment_dash_cash_amount: null,
  non_highway_fuel_daily_quantity_limit: null,
  non_highway_fuel_limit: null,
  non_highway_fuel_weekly_quantity_limit: null,
  non_highway_fuel_weekly_refresh_day: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    na: false,
  },
  card_number: null,
  odometer: null,
  other_id: null,
  paycheck_balance: null,
  plastic_type: null,
  refreshing_cash_balance: null,
  refreshing_cash_limits: null,
  trailer_number: null,
  transaction_fuel_quantity_limits: null,
  trip_number: null,
  usage_above_volume_limits: null,
  vehicle_assigned_to_card: null,
  weekly_fuel_quantity_balance: null,
  weekly_fuel_quantity_limit: null,
  weekly_product_balance: null,
  weekly_product_refresh: null,
  weekly_product_refresh_money_amount: null,
  weekly_vehicle_quantity_refresh_day: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    na: false,
  },
};

const INITIAL_STATE = {
  manageCardListView: [],
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateManageCardSuccess: false,
  isCreateManageCardError: false,
  isManageCardDetailSuccess: false,
  isManageCardDetailError: false,
  isEditManageCardSuccess: false,
  isEditManageCardError: false,
  isManageCardExportSuccess: false,
  isManageCardExportError: false,
};

export const manageCardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INIT_MANAGE_CARD:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_MANAGE_CARD_LIST_START:
    case CREATE_MANAGE_CARD_RECORD.START:
    case DELETE_MANAGE_CARD_RECORD_START:
    case FETCH_MANAGE_CARD_DETAIL.START:
    case EXPORT_MANAGE_CARD.START:
    case EDIT_MANAGE_CARD_RECORD.START:
      return { ...state, isLoading: true };

    case FETCH_MANAGE_CARD_LIST_SUCCESS: {
      const { page, content } = action.payload;

      let resetState = {
        manageCardListView: [],
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateManageCardSuccess: false,
        isCreateManageCardError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: total_pages } = page;
      return {
        ...resetState,
        manageCardListView: content,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages: total_pages,
          pageNumber: number,
        },
      };
    }

    case FETCH_MANAGE_CARD_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        manageCardListView: [],
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isManageCardDeletionSuccess: false,
        isManageCardDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_MANAGE_CARD_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isManageCardDeletionSuccess: false,
        isManageCardDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_MANAGE_CARD_RECORD_SUCCESS: {
      return {
        ...state,
        isManageCardDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

// Export Start
case EXPORT_MANAGE_CARD.ERROR: {
  const { error } = action.payload;

  return {
    ...state,
    isLoading: false,
    isLoadingPage: false,
    error,
    isManageCardExportSuccess: false,
    isManageCardExportError: true,
    shouldOpenExportConfirmationPopup: false,
  };
}

case EXPORT_MANAGE_CARD.SUCCESS: {
  const { data } = action.payload;
  downloadCSV(data,"Cards.csv");
   return {
    ...state,
    isManageCardExportSuccess: true,
    isManageCardExportError: false,
    shouldOpenExportConfirmationPopup: false,
  };
}

// Export End
    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isManageCardDeletionError: false,
        isManageCardDeletionSuccess: false,
      };
    }

    case INIT_CREATE_NEW_MANAGE_CARD_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_MANAGE_CARD_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateManageCardSuccess: false,
        isCreateManageCardError: true,
      };
    }
    case CREATE_MANAGE_CARD_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateManageCardSuccess: true,
        isCreateManageCardError: false,
      };
    }

    case FETCH_MANAGE_CARD_DETAIL.SUCCESS: {
      const temp = formFieldValueMap;
      if (action.payload && typeof action.payload === "object") {
        Object.keys(action.payload).forEach((key) => {
          if (formFieldValueMap.hasOwnProperty(key)) {
            if (
              key === "cash_refresh_days" ||
              key === "weekly_vehicle_quantity_refresh_day" ||
              key === "non_highway_fuel_weekly_refresh_day"
            ) {
              const value = JSON.parse(action.payload[key]);
              // first time this not  working after reload page it works
              if (value && typeof value === "object") {
                Object?.keys(temp[key])?.forEach((day) => {
                  // temp[key] = temp[key] ?? {};
                  temp[key][day] = value?.hasOwnProperty(day)
                    ? value[day]
                    : false;
                });
              }
            } else {
              temp[key] = action.payload[key];
            }
          }
        });
      }

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isManageCardDetailSuccess: true,
      };
    }

    case FETCH_MANAGE_CARD_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isManageCardDetailSuccess: false,
        isManageCardDetailError: true,
      };
    }

    case EDIT_MANAGE_CARD_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditManageCardSuccess: false,
        isEditManageCardError: true,
      };
    }

    case EDIT_MANAGE_CARD_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditManageCardSuccess: true,
        isEditManageCardError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateManageCardSuccess: false,
        isCreateManageCardError: false,
        isEditManageCardError: false,
        isEditManageCardSuccess: false,
        isManageCardDetailError: false,
        isManageCardExportSuccess: false,
        isManageCardExportError: false,
      };
    }
    default:
      return state;
  }
};
