export const INIT_REIMBURSEMENTS = 'INIT_REIMBURSEMENTS';
export const FETCH_REIMBURSEMENTS_LIST_START = 'FETCH_REIMBURSEMENTS_LIST_START';
export const FETCH_REIMBURSEMENTS_LIST_SUCCESS = 'FETCH_REIMBURSEMENTS_LIST_SUCCESS';
export const FETCH_REIMBURSEMENTS_LIST_ERROR = 'FETCH_REIMBURSEMENTS_LIST_ERROR';

export const DELETE_REIMBURSEMENT_RECORD_START = 'DELETE_REIMBURSEMENT_RECORD_START';
export const DELETE_REIMBURSEMENT_RECORD_SUCCESS = 'DELETE_REIMBURSEMENT_RECORD_SUCCESS';
export const DELETE_REIMBURSEMENT_RECORD_ERROR = 'DELETE_REIMBURSEMENT_RECORD_ERROR';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const INIT_CREATE_NEW_REIMBURSEMENT_SCREEN =
  'INIT_CREATE_NEW_REIMBURSEMENT_SCREEN';

export const CREATE_REIMBURSEMENT_RECORD = {
  START: 'CREATE_REIMBURSEMENT_RECORD_START',
  SUCCESS: 'CREATE_REIMBURSEMENT_RECORD_SUCCESS',
  ERROR: 'CREATE_REIMBURSEMENT_RECORD_ERROR',
};

export const EDIT_REIMBURSEMENT_RECORD = {
  START: 'EDIT_REIMBURSEMENT_RECORD_START',
  SUCCESS: 'EDIT_REIMBURSEMENT_RECORD_SUCCESS',
  ERROR: 'EDIT_REIMBURSEMENT_RECORD_ERROR',
};

export const  FETCH_REIMBURSEMENT_DETAIL = {
  START: 'FETCH_REIMBURSEMENT_DETAIL_START',
  SUCCESS: 'FETCH_REIMBURSEMENT_DETAIL_SUCCESS',
  ERROR: 'FETCH_REIMBURSEMENT_DETAIL_ERROR',
};


export const TOAST = {
  RESET: 'RESET_TOAST',
};
