import React, { useEffect } from "react";
import { loadingsShow } from "../../Services/PostAPI";
import Transactions from "../../components/Transactions";
import transactionsData from "./tableData.json";
import { APP_URLS } from "../../constants/urlConstants";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import {
  fetchTransactions,
  exportTransaction,
  initializeTransactionScreen
} from "../../Redux/Actions/transactions";
import { connect } from "react-redux";
const TransactionsScreen = (props) => {
  const {
    fetchTransactions,
    history,
    initTransactionScreen,
    showPageLevelLoader,
    transactionsListView,
    isTransactionExportError,
    isTransactionExportSuccess,
    paginationInfo,
    isLoading,
    exportTransaction,
    screenPermissions
  } = props;
  // loader none for now------------
  setTimeout(() => loadingsShow('none'), 1000)
  const loadLazyData = (props) => {
    fetchTransactions(props);
  };
  const createTransaction = () => {
    history.push(APP_URLS.TRANSACTIONS.ADD_TRANSACTION);
  };

  const editTransaction = (transactionId) => {
    const id = transactionId?.id;
    const path = generatePath(APP_URLS.TRANSACTIONS.EDIT_TRANSACTION, {
      id,
    });
    history.push(path);
  };
  useEffect(() => {
    initTransactionScreen();
    fetchTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transactionProps = {
    createTransaction,
    editTransaction,
    ...transactionsData,
    screenPermissions,
    exportTransaction:exportTransaction,
    isTransactionExportError,
    isTransactionExportSuccess,
    fetchTransactions: loadLazyData,
    ...transactionsData,
    tableData: {
      ...transactionsData.tableData,
      columns: transactionsListView?.columns,
      subcolumns: transactionsListView?.subcolumns,
      rows: transactionsListView?.rows,
    },
    paginationInfo: {
      ...transactionsData.paginationInfo,
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    isLoading,
  };
  if (showPageLevelLoader) {
    loadingsShow("block");
    return null;
  }
  loadingsShow("none");




  return (
    <div>
      <Transactions
      {... transactionProps}
      />
     
    </div>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
  initTransactionScreen: () => dispatch(initializeTransactionScreen()),
  fetchTransactions: (payload) => dispatch(fetchTransactions(payload)),
  exportTransaction: (payload) => dispatch(exportTransaction(payload)),
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    transactionsListView: selectTransactionList(state.transactionReducer),
    showPageLevelLoader: selectIsLoadingPage(state.transactionReducer),
    paginationInfo: selectPaginationInfo(state.transactionReducer),
    isPageLevelError: selectIsPageLevelError(state.transactionReducer),
    isFetchingGridData: selectIsLoading(state.transactionReducer),
  
    isTransactionExportError: selectIsTransactionExportError(
      state.transactionReducer
    ),
    isTransactionExportSuccess: selectIsTransactionExportSuccess(
      state.transactionReducer
    )
  };
};



const selectIsTransactionExportError = (transactionReducer) =>
  transactionReducer.isTransactionExportError;
const selectIsTransactionExportSuccess = (transactionReducer) =>
  transactionReducer.isTransactionExportSuccess;

const selectIsLoading = (transactionReducer) => transactionReducer.isLoading;
const selectPaginationInfo = (transactionReducer) =>
  transactionReducer.paginationInfo;
const selectIsLoadingPage = (transactionReducer) =>
  transactionReducer.isLoadingPage;
const selectIsPageLevelError = (transactionReducer) =>
  transactionReducer.isPageLevelError;
const selectTransactionList = (transactionReducer) =>
  transactionReducer.transactionsListView;


export default connect(mapStateToProps, mapDispatchToProps)(TransactionsScreen);
//export default ;
