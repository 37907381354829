import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "primereact/button";
import EzTable from "../../common/EzTable";
import { EzToast, TOAST_SEVERITY } from "../../common/EzToast";
import EzNotFound from "../../common/EzNotFound";

const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;
const CompanyComponent = (props) => {
  const {
    tableData,
    paginationInfo,
    screenPermissions,
    isPageLevelError,
    fetchCompany,
    isLoading,
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div className="ml-3">
              <h4>Company</h4>
            </div>
            <Button
              label={areFiltersVisible ? "Advanced Filter" : "Advanced Filter"}
              icon={areFiltersVisible ? "pi pi-filter-slash" : "pi pi-filter"}
              className={`p-button-text p-button-secondary`}
              onClick={() =>{toggleFilterState(!areFiltersVisible);}}
            />
          </div>
        </div>
      </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderManageCardRecords = () => tableData?.columns?.length > 0;
  const shouldRenderNotFoundView = () =>
    !shouldRenderManageCardRecords() && !shouldRenderFullPageError();

  const filters = {
    global: { value: null },
    // company_name: { filterKey: "company_name", value: null },
    // company_number: { filterKey: "company_number", value: null },
    // plastic_type: { filterKey: "plastic_typee", value: null },
    // trailer_number: { filterKey: "trailer_number", value: null },
    // card_status: { filterKey: "card_status", value: null },
    // driver_name: { filterKey: "driver_name", value: null },
    // add other fields
  };


  const tableProps = {
    areFiltersVisible,
    columns: tableData?.columns,
    paginationInfo,
    screenPermissions,
    value: tableData?.rows,
    emptyMessage: "No Company found.",
    filters,
    sortField: null,
    loading: isLoading,
    showMoreActions: false,
    loadLazyData: fetchCompany,
    };
 
  return (
    <>
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: "-70px" }}
                bgImgUrl={getIconPath("something-broke.png")}
                message={"Oops! Something broke, we are working on a fix."}
              />
            )}
            {shouldRenderManageCardRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps}  />
                </Col>
              </Row>
            )}
            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={"No Comapny records to show"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyComponent;
