export const INIT_CITY = 'INIT_CITY';
export const FETCH_CITY_LIST_START = 'FETCH_CITY_LIST_START';
export const FETCH_CITY_LIST_SUCCESS = 'FETCH_CITY_LIST_SUCCESS';
export const FETCH_CITY_LIST_ERROR = 'FETCH_CITY_LIST_ERROR';


export const FETCH_CITY_LIST_BY_PROVINCE={
    START:"FETCH_CITY_LIST_BY_PROVINCE_START",
    SUCCESS:"FETCH_CITY_LIST_BY_PROVINCE_SUCCESS",
    ERROR:'FETCH_CITY_LIST_BY_PROVINCE_ERROR'
}

export const FETCH_CITY_DETAILS={
    START:"FETCH_CITY_DETAILS_START",
    SUCCESS:"FETCH_CITY_DETAILS_SUCCESS",
    ERROR:"FETCH_CITY_DETAILS_ERROR"
}
