import Cookies from "universal-cookie";
import { post, put } from "axios";

import { getApiAsyn, deleteApi, baseUrl } from "../../PostAPI";
import { handleAPIError, APIError } from "../../common/errorHandler";
import { API_URL } from "../../../constants/urlConstants";
import { extractData } from "../../utils";
import { generateImageUrl, getDecoratedUrl } from "../../common/urlService";
import { asyncForEach } from "../../../utils/async";
import { getAllAttachment } from "../commonService";

const cookies = new Cookies();

export const fetchManageCard = async (payload) => {
  const url = getDecoratedUrl({
    url: API_URL.MANAGECARDS.FETCH_MANAGECARD,
    payload,
  });
  try {
    let result = await getApiAsyn(url, cookies.get("authorization"));

    if (result?.error) {
      throw new APIError(result);
    }

    return extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchManageCardByManageCardId = async (payload) => {
  const url = API_URL.MANAGECARDS.FETCH_BY_MANAGECARD_ID.replace(
    ":id",
    payload?.id
  );
  try {
    let result = await getApiAsyn(url, cookies.get("authorization"));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);
    if (result?.files?.length) {
      await asyncForEach(result.files, async (file, index, array) => {
        // result.files[index].url = await generateImageUrl(file.url);
        result.files[index].s3Url = await generateImageUrl(file.url);
      });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const deleteManageCardRecord = async ({ manageCardId }) => {
  try {
    let result = await deleteApi(
      `${API_URL.MANAGECARDS.DELETE_MANAGECARD}/${manageCardId}`,
      cookies.get("authorization")
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

// TODO (subham)- Refactor this to make it reusable logic
const multipartDataUploadApi = (url, { files, data }, isEdit = false) => {
  const formPayload = new FormData();
  // data part of multipart data
  const json = JSON.stringify(data);
  const blobData = new Blob([json], {
    type: "application/json",
  });
  formPayload.append("data", blobData);

  // file part of multipart data
  files?.forEach((file) => {
    formPayload.append("file", file);
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: cookies.get("authorization"),
    },
  };

  if (isEdit) {
    return put(baseUrl + url, formPayload, config);
  } else {
    return post(baseUrl + url, formPayload, config);
  }
};

const dataUploadApi = (url, data, isEdit = false) => {
  // const formPayload = new FormData();
  // // data part of multipart data
  // const json = JSON.stringify(data);
  // const blobData = new Blob([json], {
  //   type: 'application/json',
  // });
  // formPayload.append('data', blobData);

  // // file part of multipart data
  // files?.forEach((file) => {
  //   formPayload.append('file', file);
  // });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: cookies.get("authorization"),
    },
  };

  if (isEdit) {
    return put(baseUrl + url, data, config);
  } else {
    return post(baseUrl + url, data, config);
  }
};
export const createManageCardRecord = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.MANAGECARDS.CREATE_MANAGECARD}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editManageCardRecord = async (payload) => {
  const url = API_URL.MANAGECARDS.EDIT_MANAGECARDS.replace(":id", payload?.id);

  try {
    let result = await dataUploadApi(url, payload.data, true);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};


export const fetchManageCardAttachmentByManageCardId = async (payload) => {
  const url = API_URL.MANAGECARDS.FETCH_BY_MANAGECARD_ID.replace(
    ":id",
    payload?.itemId
  );
  try {
    let result = await getApiAsyn(url, cookies.get("authorization"));

    if (result?.error) {
      throw new APIError(result);
    }
    if (result?.files?.length) {
      await asyncForEach(result.files, async (file, index, array) => {
        // result.files[index].url = await generateImageUrl(file.url);
        result.files[index].s3Url = await generateImageUrl(file.url);
      });
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const downloadAllAttachment = async (payload) => {
  await getAllAttachment(API_URL.MANAGECARDS.FETCH_MANAGECARD_FILES, payload);
};


const dataExportApi = (url, data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: cookies.get("authorization"),
    },
  };
    return post(baseUrl + url, data, config);
};

export const exportManageCardRecord = async (payload) => {
  const url = API_URL.MANAGECARDS.EXPORT_MANAGECARD;
  try {
    let result = await dataExportApi(url, payload);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};