export const INIT_MANAGE_CARD = 'INIT_MANAGE_CARD';
export const FETCH_MANAGE_CARD_LIST_START = 'FETCH_MANAGE_CARD_LIST_START';
export const FETCH_MANAGE_CARD_LIST_SUCCESS = 'FETCH_MANAGE_CARD_LIST_SUCCESS';
export const FETCH_MANAGE_CARD_LIST_ERROR = 'FETCH_MANAGE_CARD_LIST_ERROR';

export const DELETE_MANAGE_CARD_RECORD_START = 'DELETE_MANAGE_CARD_RECORD_START';
export const DELETE_MANAGE_CARD_RECORD_SUCCESS =
  'DELETE_MANAGE_CARD_RECORD_SUCCESS';
export const DELETE_MANAGE_CARD_RECORD_ERROR = 'DELETE_MANAGE_CARD_RECORD_ERROR';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const INIT_CREATE_NEW_MANAGE_CARD_SCREEN =
  'INIT_CREATE_NEW_MANAGE_CARD_SCREEN';

export const CREATE_MANAGE_CARD_RECORD = {
  START: 'CREATE_MANAGE_CARD_RECORD_START',
  SUCCESS: 'CREATE_MANAGE_CARD_RECORD_SUCCESS',
  ERROR: 'CREATE_MANAGE_CARD_RECORD_ERROR',
};

export const EDIT_MANAGE_CARD_RECORD = {
  START: 'EDIT_MANAGE_CARD_RECORD_START',
  SUCCESS: 'EDIT_MANAGE_CARD_RECORD_SUCCESS',
  ERROR: 'EDIT_MANAGE_CARD_RECORD_ERROR',
};

export const FETCH_MANAGE_CARD_DETAIL = {
  START: 'FETCH_MANAGE_CARD_DETAIL_START',
  SUCCESS: 'FETCH_MANAGE_CARD_DETAIL_SUCCESS',
  ERROR: 'FETCH_MANAGE_CARD_DETAIL_ERROR',
};

export const TOAST = {
  RESET: 'RESET_TOAST',
};
export const EXPORT_MANAGE_CARD = {
  START: 'EXPORT_MANAGE_CARD_START',
  SUCCESS: 'EXPORT_MANAGE_CARD_SUCCESS',
  ERROR: 'EXPORT_MANAGE_CARD_ERROR',
};
