import React, { useEffect } from "react";
import { loadingsShow } from "../../Services/PostAPI";
import userData from "./tableData.json";
import { APP_URLS } from "../../constants/urlConstants";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import UserComponent from "../../components/User";

import {
  deleteUserRecord,
  fetchUser,
  initializeUserScreen,
  userFetchFilesByUserId,
  setDeleteConfirmationPopupVisibility,
} from "../../Redux/Actions/user";
import { connect } from "react-redux";

const UserScreen = (props) => {
  const {
    fetchUsers,
    history,
    initUserScreen,
    showPageLevelLoader,
    userList,
    paginationInfo,
    isLoading,
    role,
  } = props;

  useEffect(() => {
    initUserScreen();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(role);
  const createUser = () => {
    history.push(APP_URLS.USERS.ADD_USERS);
  };
  const editUser = (user) => {
    const id = user.id;
    console.log(id);
    const path = generatePath(APP_URLS.MANAGECARDS.EDIT_MANAGECARDS, {
      id,
    });
    history.push(path);
  };

  const loadLazyData = (props) => {
    fetchUsers(props);
  };
  const readUser = (userId) => {
    const path = generatePath(APP_URLS.USERS.VIEW_USER, { id: userId });
    history.push(path);
  };
  const usersProps = {
    createUser,
    editUser,
    readUser,
    loadLazyData,
    ...userData,
    tableData: {
      ...userData.tableData,
      columns: userList?.columns,
      rows: userList?.rows,
    },
    paginationInfo: {
      ...userData.paginationInfo,
      pageSize: paginationInfo?.pageSize,
      pageNumber: paginationInfo?.pageNumber,
      totalPages: paginationInfo?.totalPages,
      totalRecords: paginationInfo?.totalRecords,
    },
    isLoading,
  };

  if (showPageLevelLoader) {
    loadingsShow("block");
    return null;
  }

  loadingsShow("none");
  return (
    <div>
      {console.log("YU", usersProps)}
      <UserComponent {...usersProps} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initUserScreen: () => dispatch(initializeUserScreen()),
    fetchUsers: (payload) => dispatch(fetchUser(payload)),
    deleteUserRecord: (payload) => dispatch(deleteUserRecord(payload)),
    setDeleteConfirmationPopupVisibility: (payload) =>
      dispatch(setDeleteConfirmationPopupVisibility(payload)),
    fetchUserAttachmentsByUserId: (payload) =>
      dispatch(userFetchFilesByUserId(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    userList: selectUsersList(state.userReducer),
    showPageLevelLoader: selectIsLoadingPage(state.userReducer),
    paginationInfo: selectPaginationInfo(state.userReducer),
    isPageLevelError: selectIsPageLevelError(state.userReducer),
    isFetchingGridData: selectIsLoading(state.userReducer),
    isUserDeletionError: selectIsUserDeletionError(state.userReducer),
    isUserDeletionSuccess: selectIsUserDeletionSuccess(state.userReducer),
    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(
      state.userReducer
    ),
  };
};
const selectIsUserDeletionError = (userReducer) =>
  userReducer.isUserDeletionError;
const selectIsUserDeletionSuccess = (userReducer) =>
  userReducer.isUserDeletionSuccess;
const selectIsLoading = (userReducer) => userReducer.isLoading;
const selectShouldOpenDeleteConfirmationPopup = (userReducer) =>
  userReducer.shouldOpenDeleteConfirmationPopup;
const selectPaginationInfo = (userReducer) => userReducer.paginationInfo;
const selectIsLoadingPage = (userReducer) => userReducer.isLoadingPage;
const selectIsPageLevelError = (userReducer) => userReducer.isPageLevelError;
const selectUsersList = (userReducer) => userReducer.userList;
export default connect(mapStateToProps, mapDispatchToProps)(UserScreen);
