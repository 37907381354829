import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getApiAsyn } from '../Services/PostAPI';
import Comingsoonpage from '../common/Comingsoon';
const cookies = new Cookies();

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
      rangeFlow: 'Daily',
      options: {
        chart: {
          id: 'basic-bar',
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: 'Count',
          data: [],
        },
      ],

      optionsMixedChart: {
        chart: {
          id: 'basic-bar',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },
        stroke: {
          width: [4, 0, 0],
        },
        xaxis: {
          categories: [],
        },
        markers: {
          size: 6,
          strokeWidth: 3,
          fillOpacity: 0,
          strokeOpacity: 0,
          hover: {
            size: 8,
          },
        },
        yaxis: {
          tickAmount: 5,
          min: 0,
          max: 100,
        },
      },
      seriesMixedChart: [],
    };
  }
  getTripChartData = async (scales) => {
    let res = await getApiAsyn(
      `trips?intent=summary&interval=${scales}`,
      cookies.get('authorization')
    );
    this.setState({
      rangeFlow: scales,
    });
    console.log('day', res);
    if (res && res.status !== false) {
      let data = res;
      data.seriesMixedChart = res.series_mixed_chart;
      let month = [];
      for (let i = 0; i < data.seriesMixedChart.length; i++) {
        month.push(data.seriesMixedChart[i].scale);
      }
      let uniqueMonth = month.filter(function (item, pos) {
        return month.indexOf(item) === pos;
      });

      uniqueMonth = data.categories;
      let seriesMixedChart = data.seriesMixedChart;
      let mTotal = [];
      let mComplete = [];
      let mInProgress = [];
      let mStarted = [];
      for (let i = 0; i < seriesMixedChart.length; i++) {
        for (let m = 0; m < uniqueMonth.length; m++) {
          if (
            seriesMixedChart[i].scale === uniqueMonth[m] &&
            seriesMixedChart[i]._status === 'New'
          ) {
            mTotal.push(seriesMixedChart[i].count);
          }
          if (
            seriesMixedChart[i].scale === uniqueMonth[m] &&
            seriesMixedChart[i]._status === 'COMPLETE'
          ) {
            mComplete.push(seriesMixedChart[i].count);
          }
          if (
            seriesMixedChart[i].scale === uniqueMonth[m] &&
            seriesMixedChart[i]._status === 'STARTED'
          ) {
            mStarted.push(seriesMixedChart[i].count);
          }
          if (
            seriesMixedChart[i].scale === uniqueMonth[m] &&
            seriesMixedChart[i]._status === 'Total'
          ) {
            mTotal.push(seriesMixedChart[i].count);
          }
        }
      }

      this.setState({
        optionsMixedChart: {
          xaxis: {
            categories: uniqueMonth,
          },
          yaxis: {
            tickAmount: mTotal,
            min: 0,
            max: mTotal,
          },
        },
        seriesMixedChart: [
          {
            name: 'Total Trips',
            type: 'line',
            data: mTotal,
          },
          {
            name: 'Started Trips',
            type: 'column',
            data: mStarted,
          },
          {
            name: 'Progress Trips',
            type: 'column',
            data: mInProgress,
          },
          {
            name: 'Complete Trips',
            type: 'column',
            data: mComplete,
          },
        ],
      });
    }
  };

  getOrderBarChartData = async () => {
    let res = await getApiAsyn(
      `orders/?intent=summary`,
      cookies.get('authorization')
    );
    if (res.length > 0) {
      let cat = res;
      let categories = [],
        data = [];
      for (let i = 0; i < cat.length; i++) {
        categories.push(cat[i].category);
        data.push(cat[i].count);
      }
      this.setState({
        options: {
          xaxis: {
            categories: categories,
          },
        },
      });
      this.setState((prevState) => {
        let { series } = prevState;

        series = [
          {
            name: 'Count',
            data: data,
          },
        ];

        return { series };
      });
    }
  };

  handleSetCookies = () => {
    var date = new Date();
    date.setTime(date.getTime() + 60 * 1000);
    console.log(date);
    cookies.set('ten', 'baid', {
      expires: date,
    });
  };

  async componentDidMount() {
    await this.getOrderBarChartData();
    await this.getTripChartData('m');
  }

  render() {
    if (
      cookies.get('authorization') == null ||
      cookies.get('authorization') === undefined
    ) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <div className="paddingbox">
          <div className="dbcontainer">
            <h3 onClick={this.handleSetCookies}>Dashboard</h3>

        
            <Comingsoonpage />
      
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
