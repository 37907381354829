import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "primereact/button";
import EzTable from "../../common/EzTable";
import EzNotFound from "../../common/EzNotFound";
import { COMPLIANCE_DATE_DATA_KEY } from "../../Redux/Reducers/compliance";
import EzDialog from "../../common/EzDialog";
import PermissionScreen from "./PermissionScreen";
const getIconPath = (imgPathDelta = "/no-record.png") =>
  process.env.PUBLIC_URL + imgPathDelta;
const UserComponent = (props) => {
  const {
    tableData,
    paginationInfo,
    screenPermissions,
    createUser,
    readUser,
    editUser,
    isPageLevelError,
    fetchUser,
    loadLazyData,
    isLoading,
  } = props;

  const [areFiltersVisible, toggleFilterState] = useState(false);
  const [areUserPermission, toggleUserPermission] = useState(false);
  const [userPermission, setUserPermission] = useState(null);

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div className="ml-3">
              <h4>User</h4>
            </div>
            <Button
              label={areFiltersVisible ? "Advanced Filter" : "Advanced Filter"}
              icon={areFiltersVisible ? "pi pi-filter-slash" : "pi pi-filter"}
              className={`p-button-text p-button-secondary`}
              onClick={() => {
                toggleFilterState(!areFiltersVisible);
              }}
            />
          </div>
          {/* <Button
            label={"Add New User"}
            className={` p-button-raised`}
            icon={"pi pi-user-plus"}
            style={{ borderRadius: "10px" }}
            onClick={createUser}
          /> */}
        </div>
      </div>
    );
  };
  const shouldRenderFullPageError = () => isPageLevelError;
  const shouldRenderUserRecords = () => tableData?.columns?.length > 0;
  const shouldRenderUserPermission = () => areUserPermission;
  const shouldRenderNotFoundView = () =>
    !shouldRenderUserRecords() && !shouldRenderFullPageError();

  const filters = {
    global: { value: null },
    company_name: { filterKey: "company_name", value: null },
    company_number: { filterKey: "company_number", value: null },
    plastic_type: { filterKey: "plastic_typee", value: null },
    trailer_number: { filterKey: "trailer_number", value: null },
    card_status: { filterKey: "card_status", value: null },
    driver_name: { filterKey: "driver_name", value: null },
    // add other fields
  };
  const onDownloadRecord = (data) => {
    console.log(data);
  };

  const onViewPermission = (data) => {
    console.log(data);
    data = { ...data, permissions: {} };
    setUserPermission(data);
    toggleUserPermission(!areUserPermission);
    console.log(data);
  };

  const onReadRecord = (data) => {
    console.log(data, "test");
    // history.push(APP_URLS.USERS.VIEW_USER);
    readUser(data.profileid);
  };

  const onHide = () => {
    toggleUserPermission(!areUserPermission);
  };

  const dailogProps = {
    header: "Permission",
    //  width,
    //  height,
    onHide,
    //  visible,
    //  maximizable,
    //  isLoading,
    //  footer
  };

  const tableProps = {
    areFiltersVisible,
    columns: tableData?.columns,
    paginationInfo,
    screenPermissions,
    value: tableData?.rows,
    emptyMessage: "No User found.",
    filters,
    loading: false,
    showMoreActions: false,
    onDownloadRecord,
    onViewPermission,
    onReadRecord,
    sortField: COMPLIANCE_DATE_DATA_KEY,
    loadLazyData,
  };
  return (
    <>
      <div className="paddingbox">
        <div className="containerbox">
          {renderPageHeaderSection()}
          <div className="mTop15">
            {shouldRenderFullPageError() && (
              <EzNotFound
                messageStyle={{ marginLeft: "-70px" }}
                bgImgUrl={getIconPath("something-broke.png")}
                message={"Oops! Something broke, we are working on a fix."}
              />
            )}
            {shouldRenderUserRecords() && (
              <Row>
                <Col md={12}>
                  <EzTable {...tableProps} />
                </Col>
              </Row>
            )}
            {shouldRenderUserPermission() && (
              <EzDialog {...dailogProps}>
                <PermissionScreen user={userPermission} />
              </EzDialog>
            )}

            {shouldRenderNotFoundView() && (
              <EzNotFound
                bgImgUrl={getIconPath()}
                message={"No User records to show"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserComponent;
