import React from "react";
import { Route } from "react-router-dom";

import { APP_URLS } from "../../constants/urlConstants";
import Profile from "../../Profile/Profile";

const ProfileRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update = false } = access;
  // console.log(access, props);

  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <Profile {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      

    </>
  );
};

export default ProfileRoutes;
