import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

const EzControlledInput = (props) => {
    const {
        control,
        name,
        disabled,
        type,
        rules,
        labelClassName,
        onChange,
        label,
        errorMsg,
        isError,
    } = props;
   

    const getLabelClassName = () => {
        return classNames({
            'p-error': isError,
            labelClassName,
        });
    };

    return (
        <div className="field">
            <span >
                <label htmlFor={name} className={getLabelClassName()}>
                    {label}
                    {rules?.required ? <span style={{ color: 'red' }}>* </span> : null}
                </label>
                <Controller
                    name={name}
                    control={control}
                    rules={rules}

                    render={({ field, fieldState }) => (
                        <InputText
                            type={type}
                            id={field.name}
                            name={field.name}
                            onChange={onChange}
                            disabled={disabled}
                            {...field}
                            className={classNames({
                                'p-invalid': fieldState.invalid,
                            })}
                        />
                    )}
                />


            </span>
            {errorMsg}
        </div>
    );
};

EzControlledInput.defaultProps = {
    labelClassName: '',
    disabled: false,
    type: 'text',
};

export default EzControlledInput;
