export const getEmbeddedContentFromResponse = (_embedded) => {
  return _embedded?.content;
};

export const extractData = (result, isEmbeddedContent = true) => {
  
  //TODO (subham): This structure is hacky
  // if (!isEmbeddedContent) {
  //   delete result.APIResult;
  //   return { content: Array.from(result) };
  // }

  let modifiedResult = {
    ...result,
    content: getEmbeddedContentFromResponse(result?._embedded),
  };
  delete modifiedResult._embedded;
  return modifiedResult;
};

export const mstimeconversion =(timestamp) =>{
const date = new Date(timestamp);
return date
}
export const mstimeconversionUTC =(timestamp) =>{
const date = new Date(timestamp);
const cstDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
const year = cstDate.getFullYear();
const month = ('0' + (cstDate.getMonth() + 1)).slice(-2); // Months are zero-indexed, so add 1 and pad with leading zero
const day = ('0' + cstDate.getDate()).slice(-2); // Pad with leading zero
const hours = ('0' + cstDate.getHours()).slice(-2); // Pad with leading zero
const minutes = ('0' + cstDate.getMinutes()).slice(-2); // Pad with leading zero
const seconds = ('0' + cstDate.getSeconds()).slice(-2); // Pad with leading zero

const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
return formattedDate
}