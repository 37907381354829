import { DEFAULT_TOAST_LIFE } from "../../../common/EzToast";
import {
  INIT_MANAGE_CARD,
  FETCH_MANAGE_CARD_LIST_ERROR,
  FETCH_MANAGE_CARD_LIST_START,
  FETCH_MANAGE_CARD_LIST_SUCCESS,
  DELETE_MANAGE_CARD_RECORD_START,
  DELETE_MANAGE_CARD_RECORD_SUCCESS,
  DELETE_MANAGE_CARD_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_MANAGE_CARD_SCREEN,
  CREATE_MANAGE_CARD_RECORD,
  EDIT_MANAGE_CARD_RECORD,
  FETCH_MANAGE_CARD_DETAIL,
  TOAST,
  EXPORT_MANAGE_CARD
} from "../../../constants/action-types/manageCard";
import { manageCardService } from "../../../Services";
import { goBack } from "../../../utils/gotoback";

export const initializeManageCardScreen = () => ({ type: INIT_MANAGE_CARD });

export const manageCardFetchStart = (payload) => ({
  type: FETCH_MANAGE_CARD_LIST_START,
  payload,
});

export const manageCardFetchSuccess = (payload) => ({
  type:FETCH_MANAGE_CARD_LIST_SUCCESS,
  payload,
});

export const manageCardFetchError = (payload) => ({
  type: FETCH_MANAGE_CARD_LIST_ERROR,
  payload,
});

export const fetchManageCard = (payload) => {
  return (dispatch) => {
    dispatch(manageCardFetchStart(payload));

    manageCardService.fetchManageCard(payload).then((manageCardData) => {
      if (!manageCardData.isError) {
        dispatch(manageCardFetchSuccess(manageCardData));
      
      } else {
        dispatch(manageCardFetchError(manageCardData));
      }
    });
  };
};

export const manageCardDetailFetchStart = (payload) => ({
  type: FETCH_MANAGE_CARD_DETAIL.START,
  payload,
});

export const manageCardDetailFetchSuccess = (payload) => ({
  type: FETCH_MANAGE_CARD_DETAIL.SUCCESS,
  payload,
});

export const manageCardDetailFetchError = (payload) => ({
  type: FETCH_MANAGE_CARD_DETAIL.ERROR,
  payload,
});

export const fetchManageCardByManageCardId = (payload) => {
  return (dispatch) => {
    dispatch(manageCardDetailFetchStart(payload));

    manageCardService
      .fetchManageCardByManageCardId(payload)
      .then((manageCardData) => {
        if (!manageCardData.isError) {
          dispatch(manageCardDetailFetchSuccess(manageCardData));
        } else {
          dispatch(manageCardDetailFetchError(manageCardData));
        }
      });
  };
};

// delete manageCard
export const deleteManageCardRecordStart = () => ({
  type: DELETE_MANAGE_CARD_RECORD_START,
});

export const deleteManageCardRecordSuccess = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_SUCCESS,
  payload,
});

export const deleteManageCardRecordError = (payload) => ({
  type: DELETE_MANAGE_CARD_RECORD_ERROR,
  payload,
});

export const deleteManageCardRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteManageCardRecordStart());

    manageCardService
      .deleteManageCardRecord({ manageCardId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteManageCardRecordSuccess());
          dispatch(fetchManageCard(payload?.fetchManageCardParams));
        } else {
          dispatch(deleteManageCardRecordError(data));
        }
      });
  };
};

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const createNewManageCardScreenInitStart = (payload) => ({
  type: INIT_CREATE_NEW_MANAGE_CARD_SCREEN,
  payload,
});

export const initCreateNewManageCardScreen = (payload) => {
  return (dispatch) => {
    dispatch(createNewManageCardScreenInitStart());
  };
};


export const createManageCardRecordStart = () => ({
  type: CREATE_MANAGE_CARD_RECORD.START,
});

export const createManageCardRecordSuccess = (payload) => ({
  type: CREATE_MANAGE_CARD_RECORD.SUCCESS,
  payload,
});

export const createManageCardRecordError = (payload) => ({
  type: CREATE_MANAGE_CARD_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createManageCard = (payload) => {
  console.log("payload",payload);
  return (dispatch) => {
    dispatch(createManageCardRecordStart());
    manageCardService.createManageCardRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createManageCardRecordSuccess());
        goBack();
      } else {
        dispatch(createManageCardRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editManageCardRecordStart = (payload) => ({
  type: EDIT_MANAGE_CARD_RECORD.START,
  payload
});

export const editManageCardRecordSuccess = (payload) => ({
  type: EDIT_MANAGE_CARD_RECORD.SUCCESS,
  payload,
});

export const editManageCardRecordError = (payload) => ({
  type: EDIT_MANAGE_CARD_RECORD.ERROR,
  payload,
});

export const editManageCard = (payload) => {
  return (dispatch) => {
    dispatch(editManageCardRecordStart());
    manageCardService.editManageCardRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editManageCardRecordSuccess());
        goBack();
      } else {
        dispatch(editManageCardRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const manageCardFetchFilesByManageCardId = (payload) => async (
  dispatch
) => {
  // dispatch(attachmentsFilesFetchStart(payload));
  // const {files} = await manageCardService.fetchManageCardAttachmentByManageCardId(payload);
  // if(files) dispatch(attachmentsFilesFetchSuccess(files));
};

export const downloadAllAttachmentForManageCardId = (payload) => async (
  dispatch
) => {
  // dispatch(downloadingAllAttachmentStart())
  // await manageCardService.downloadAllAttachment(payload);
  // dispatch(downloadingAllAttachmentComplete());
};


// Export Cards

export const exportManageCardRecordStart = (payload) => ({
  type: EXPORT_MANAGE_CARD.START,
  payload
});

export const exportManageCardRecordSuccess = (payload) => ({
  type: EXPORT_MANAGE_CARD.SUCCESS,
  payload,
});

export const exportManageCardRecordError = (payload) => ({
  type: EXPORT_MANAGE_CARD.ERROR,
  payload,
});

export const exportManageCard = (payload) => {
  return (dispatch) => {
    dispatch(exportManageCardRecordStart());
    manageCardService.exportManageCardRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportManageCardRecordSuccess(data));
      } else {
        dispatch(exportManageCardRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};