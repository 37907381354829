import React from "react";
import { Route } from "react-router-dom";

import { APP_URLS } from "../../constants/urlConstants";
import UsersScreen from "../../containers/UserScreen";
import CreateUserContainer from "../../containers/UserScreen/CreateUser";

const UserRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update  } = access;
  return (
    <>
       {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <UsersScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
       {!!create && (
        <Route
          path={APP_URLS.USERS.ADD_USERS}
          component={CreateUserContainer}
        />
        )}
        {!!update && (
        <Route
          path={APP_URLS.USERS.EDIT_USER}
          render={(routeProps) => <CreateUserContainer {...routeProps} isEdit={true} />}
        />
      )}
 
    </>
  );
};

export default UserRoutes;
