import React from "react";
import { Route } from "react-router-dom";

import { APP_URLS } from "../../constants/urlConstants";
import CreateTransactions from "../../containers/TransactionsScreen/CreateTransactions";
import NotFound from "../../NotFound/NotFound";
import ReportingScreen from "../../containers/ReportingScreen";

const ReportingRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update } = access;
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <ReportingScreen {...routeProps}  />
          )}
        />)}
      {!!create && (
        <Route
          path={APP_URLS.REPORTING.ADD_REPORTING}
          component={CreateTransactions}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.REPORTING.EDIT_REPORTING}
          render={(routeProps) => (
            <CreateTransactions {...routeProps} isEdit={true} />
          )}
        />
      )}
    
    </>
  );
};

export default ReportingRoutes;
